<template>
  <div class="home">
    <el-row>
      <el-col :span="24">
        <div class="head">
<!--            <el-col :span="2">-->
<!--              <img style="padding-left:8px;margin-top:4px" width="80px" height="80px" alt="艾沐佳"-->
<!--                   @click="switchMainComp" src="https://www.tcpcat.com/r/ajc/amjtm.png">-->
<!--            </el-col>-->
              <div class="right">
                <h1 style="padding-left:0px;font-weight:300;font-size: 33px">{{ info.name }}艾灸设备管理系统 —— {{ pagetitle }}</h1>
                <el-menu
                    :default-active=activeIndex
                    class="el-menu"
                    mode="horizontal"
                    background-color="#002c0c"
                    text-color="#fff"
                    active-text-color="#ffd04b">
<!--                  <el-menu-item style="font-size: 22px" @click="switchMainComp" index="1">主页</el-menu-item>-->
                  <el-menu-item v-if="pg_list.ui_device"  style="font-size: 22px" @click="switchDeviceComp" index="2">设备管理</el-menu-item>
                  <el-menu-item v-if="pg_list.ui_coupon"  style="font-size: 22px" @click="switchCouponComp" index="3">滤芯管理</el-menu-item>
                  <el-menu-item v-if="pg_list.ui_coupon"  style="font-size: 22px" @click="switchServiceComp" index="4">售后管理</el-menu-item>
                  <el-menu-item v-if="pg_list.ui_userpage"  style="font-size: 22px" @click="switchUserComp" index="5">账号管理</el-menu-item>
                </el-menu>
                <div>
                  <i style="margin-right: 8px;"
                     class="el-icon-user-solid"></i><span >操作员：{{ OpForm.user }}</span>
                  <el-button style="margin-left: 20px" type="primary" icon="el-icon-map-location" @click="openMap" plain>设备地图</el-button>
                  <el-button style="margin-left: 10px" type="primary" icon="el-icon-setting" @click="openSetting">后台设置</el-button>
                  <el-button type="danger" icon="el-icon-close" @click="exit">退出登陆</el-button>

                </div>
              </div>
          <!-- <el-divider></el-divider> -->
        </div>
      </el-col>
    </el-row>
    <el-container style="height: 100%; border: 1px solid #eee">


      <!-- 后台系统设置 Start -->
      <el-dialog title="后台系统设置" :visible.sync="SettingDialog" width="500px">
        <el-form>
          <el-tabs type="border-card">
            <el-tab-pane label="操作员">
              <el-form :inline="true" :model="OpForm" class="demo-form-inline">
                <el-form-item label="操作人员姓名：">
                  <el-input v-model="OpForm.user" placeholder="操作员"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="OPSave">保 存</el-button>
                </el-form-item>
              </el-form>
              <div>注：操作员保存在本地电脑，更换电脑或清除缓存需重新设置！</div>
              <el-divider></el-divider>

              <div>后台系统版本：V1.7_AC16 </div>

              <!--  RechargeTimesDialog = false-->

            </el-tab-pane>

               <el-tab-pane label="登录密码修改">
             <el-form>
               <el-form-item label="当前密码" label-width="80px">
                 <el-input v-model="InputOldPassword" show-password autocomplete="off" maxlength="32"></el-input>
               </el-form-item>
               <el-form-item label="新密码" label-width="80px">
                 <el-input v-model="InputPassword1" show-password autocomplete="off" maxlength="32"></el-input>
               </el-form-item>
               <el-form-item label="确认密码" label-width="80px">
                 <el-input v-model="InputPassword2" show-password autocomplete="off" maxlength="32"></el-input>
               </el-form-item>
               <el-form-item label="" label-width="80px">
                 <el-button type="primary" @click="ChangePassword">修 改 密 码</el-button>
               </el-form-item>
             </el-form>
              </el-tab-pane>

            <el-tab-pane label="锁机密码修改">
              <el-form>
                <el-form-item label="当前密码" label-width="80px">
                  <el-input v-model="InputOldOPPassword" show-password autocomplete="off" maxlength="32"></el-input>
                </el-form-item>
                <el-form-item label="新密码" label-width="80px">
                  <el-input v-model="InputNewOPPassword1" show-password autocomplete="off" maxlength="32"></el-input>
                </el-form-item>
                <el-form-item label="重复密码" label-width="80px">
                  <el-input v-model="InputNewOPPassword2" show-password autocomplete="off" maxlength="32"></el-input>
                </el-form-item>
                <el-form-item label="" label-width="80px">
                  <el-button type="primary" @click="ChangeOPPassword">修改锁机密码</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>


        </el-form>
      </el-dialog>
      <!-- 后台系统设置 END -->

      <el-main>
        <div>
          <keep-alive>
            <component :is="taowa"></component>
          </keep-alive>
        </div>
      </el-main>
    </el-container>
    <div class="foot">Copyright © 2025 {{ info.name }}艾灸设备管理系统</div>

  </div>
</template>

<script>
import Vue from 'vue'
import {Button} from 'element-ui'

Vue.use(Button)

export default {
  components: {
    devicecomp: (resolve) => require(['../components/devicecomp'], resolve),
    maincomp: (resolve) => require(['../components/maincomp'], resolve),
    usercomp: (resolve) => require(['../components/usercomp.vue'], resolve),
    couponcomp: (resolve) => require(['../components/couponcomp.vue'], resolve),
    servicecomp: (resolve) => require(['../components/servicecomp.vue'], resolve),
  },
  data() {
    return {
      pagetitle: '设备',
      activeIndex: "2",
      info: {},
      taowa: 'devicecomp',
      pg_list: {
        ui_device: false,
        ui_userpage: false,
        ui_coupon:false,
      },
      // setting
      SettingDialog: false,
      InputOldPassword: "",
      InputPassword1: "",
      InputPassword2: "",
      InputOldOPPassword: "",
      InputNewOPPassword1: "",
      InputNewOPPassword2: "",
      OpForm:{
        user:""
      }
    }
  },
  mounted() {

  },
  created() {
    this.info = this.$store.state.userInfo
    this.activeIndex = "2"
    this.PG_Query();
    let op = localStorage.getItem('op');
    if (op === null || op === '') {
      op = this.info.name;
      localStorage.setItem('op', op);
    }
    this.OpForm.user = op
  },
  beforeDestroy() {
  },
  methods: {
    switchMainComp() {
      this.taowa = 'maincomp'
      this.pagetitle = '主页'
      this.activeIndex = "1"
    },
    switchDeviceComp() {
      this.taowa = 'devicecomp'
      this.pagetitle = '设备'
      this.activeIndex = "2"
    },
    switchUserComp() {
      this.taowa = 'usercomp'
      this.pagetitle = '账号'
      this.activeIndex = "5"
    },
    switchCouponComp(){
      this.taowa = 'couponcomp'
      this.pagetitle = '滤芯'
      this.activeIndex = "3"
    },
    switchServiceComp(){
      this.taowa = 'servicecomp'
      this.pagetitle = '售后'
      this.activeIndex = "4"
    },
    async PG_Query() {
      let res = await this.$api.pg_query({
        pg: this.pg_list,
      })

      // console.log("pg ->", res)
      if (res.status == 1) {
        this.pg_list = res.data
      }

      console.log("====>",this.pg_list)
    },
    OPSave(){
      if(this.OpForm.user == ""){
        this.OpForm.user = this.info.name;
      }
      localStorage.setItem('op', this.OpForm.user );
      this.$message({
        message: '操作员已保存',
        type: 'success',
      })
    },
    openMap(){
      const url = `https://x1.ai-zhineng.cn/map/#/?p=18927A017C53EE7E067AD985C32FC0256B42FB2DD6BDB44470374CDACD4C63DAB4B72ADA6C3954F25B6D2429EAE46B277EB3EE03B99148A84F60DC3645B6CC03&u=${this.info.uid}`;
      window.open(url, '_blank');
    },
    openSetting(){
      let op = localStorage.getItem('op');
      if (op === null || op === '') {
        op = this.info.name;
        localStorage.setItem('op', op);
      }
      this.OpForm.user=op

      if (this.OpForm.user == ""){
        this.info.name
      }
      this.InputOldPassword='';
      this.InputPassword1='';
      this.InputPassword2='';
      this.InputOldOPPassword='';
      this.InputNewOPPassword1='';
      this.InputNewOPPassword2='';
      this.SettingDialog = true
    },
    async ChangeOPPassword() {
      if (this.InputNewOPPassword1 === this.InputNewOPPassword2) {
        let res = await this.$api.change_op_password({
          uid: ""+this.info.uid,
          old:""+this.InputOldOPPassword,
          pw: ""+this.InputNewOPPassword2,
        })
        console.log(res)
        if (res.status == 1) {
          this.$message({
            message: '锁机密码修改成功',
            type: 'success',
          })
        }
      } else {
        this.$notify({
          title: '错误',
          message: "两次输入新密码不一致",
          type: 'error',
        })
      }
    },
    async ChangePassword() {
      if (this.InputPassword1 === this.InputPassword2) {
        let res = await this.$api.change_password_with_old({
          uid: ""+this.info.uid,
          old:""+this.InputOldPassword,
          pw: ""+this.InputPassword1,
        })
        console.log(res)
        if (res.status == 1) {
          this.$message({
            message: '密码修改成功',
            type: 'success',
          })
        }
      } else {
        this.$notify({
          title: '错误',
          message: "两次输入密码不一致",
          type: 'error',
        })
      }
    },
    exit() {
      this.$confirm('此操作将退出登陆, 是否继续?', '提示', {
        confirmButtonText: '确定退出',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.$notify({
              title: '退出成功',
              message: '请重新登录',
              type: 'success',
            })
            window.sessionStorage.clear()
            this.$router.push('/login')
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消退出',
            })
          })
    },
  },
}
</script>

<style scoped>
.right {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  /* text-align: ce; */
  align-items: center;
  justify-content: space-between;
  margin-right: 25px;
}

.head {
  background-color: #002c0c;
  padding: 5px;
  padding-left: 30px;
  color: white;
  /* padding: 10px; */
}

.el-header {
  background-color: rgb(231, 244, 255);
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

.foot {
  width: 100%;
  font-size: 14px;
  text-align: center;
}

/* .el-main {
  background: #eee;
} */
</style>
