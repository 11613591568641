<template>
  <div class="devcomp">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" class="demo-form-inline">


<!--            <el-form-item label="按所属用户筛选：">-->
<!--              <el-select v-model="usvalue" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in uselect"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item style="margin-left: 20px;" label="按状态筛选：">
              <el-select @change="onRefresh" style="width: 150px" v-model="ssvalue" placeholder="请选择">
                <el-option
                    v-for="item in sselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="  ">
              <el-input style="width: 275px" placeholder="次数小于" v-model="leinput"
                        maxlength="2" clearable @keyup.enter.native="onRefresh()" @clear="onRefresh()">
                <template slot="prepend">剩余次数 ≤</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search"></el-button>

              </el-input>
            </el-form-item>
            <el-form-item label="  ">
              <el-input style="width: 325px" placeholder="设备编号筛选" v-model="searchsn"
                        maxlength="15" clearable @keyup.enter.native="onRefresh()" @clear="onRefresh()">
<!--                <template slot="prepend">设备编号SN：</template>-->
                <el-select v-model="SNorCID" slot="prepend" style="width: 135px" @change="onRefresh">
                  <el-option label="设备编号筛选" key="cid" value="cid"></el-option>
                  <el-option label="4G编号筛选" key="sn" value="sn"></el-option>
                </el-select>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search"></el-button>

              </el-input>
            </el-form-item>
            <el-form-item label="  ">
              <el-input style="width: 320px" placeholder="手机号筛选" v-model="searchphone"
                        maxlength="11" clearable @keyup.enter.native="onRefresh()" @clear="onRefresh()">
                <template slot="prepend">用户手机号：</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search"></el-button>

              </el-input>
            </el-form-item>


            <el-form-item label="  ">
              <el-input style="width: 300px" placeholder="输入客户名" v-model="searchinput" clearable  @clear="onRefresh()" @keyup.enter.native="onRefresh()">
                <template slot="prepend">客户名称:</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search"></el-button>
              </el-input>
            </el-form-item>

            <el-form-item label="  ">
              <el-checkbox v-model="ordercheck" @change="onRefresh">按活跃时间排序</el-checkbox>
            </el-form-item>



          </el-form>

        </div>
        <div class="search">


        </div>

        <el-table
            :data="tbdata"
            border
            stripe
            height="660"
            style="width: 100%; margin: 3px"
        >
          <el-table-column
              v-if="SNorCID=='sn'"
              prop="sn"
              align="center"
              label="4G编号(IMEI)"
              width="145"
          >
          </el-table-column>
          <el-table-column
              v-if="SNorCID=='cid'"
              prop="cid"
              align="center"
              label="设备编号(SN)"
              width="135"
          >
          </el-table-column>

          <el-table-column prop="na" label="客户名称" align="center">
          </el-table-column>

          <el-table-column prop="ph" width="130" label="手机号" align="center">
          </el-table-column>

<!--          <el-table-column prop="model" width="80" label="型号" align="center">-->
<!--          </el-table-column>-->


          <el-table-column prop="st" width="90" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.fly=='fly'">
                <el-tag
                    type="primary"
                    disable-transitions>
                <i class="el-icon-s-promotion"></i>飞行</el-tag>
              </span>
              <span v-if="scope.row.fly!='fly'">
              <el-tag
                  v-if="!scope.row.lock"
                  :type="
                    scope.row.st === '在线'
                      ? 'success'
                      : scope.row.st === '运行'
                      ? 'danger'
                      : 'info'
                  "
                  disable-transitions>{{scope.row.st}}</el-tag>

              <el-tag
                  v-if="scope.row.lock"
                  type="warning"
                  disable-transitions>
                <i class="el-icon-lock"></i>锁机</el-tag>
                </span>
            </template>
          </el-table-column>

<!--          <el-table-column prop="pt" width="80" label="净化温度" align="center"   :formatter="temperatureFormatter">-->
<!--          </el-table-column>-->

         <!-- <el-table-column prop="lt" width="80" label="仓内温度" align="center"  :formatter="temperatureFormatter"> -->
<!--          </el-table-column>-->

          <el-table-column prop="cnt" width="80" label="剩余次数" align="center"  :formatter="countFormatter">
          </el-table-column>

          <el-table-column label="滤芯更换" width="80" align="center">
            <template #default="scope">
              <span :style="scope.row.fc!=scope.row.cc?
              'color: red;':'color: darkgreen;'">{{ scope.row.fc }}次</span>
            </template>
          </el-table-column>

          <el-table-column prop="cc" width="80" label="充次计数" align="center"  :formatter="countFormatter">
          </el-table-column>

          <el-table-column prop="svt" width="80" label="售后记录" align="center"  :formatter="countFormatter">
          </el-table-column>

          <!-- <el-table-column prop="counts" width="105" label="剩余次数" align="center">
          </el-table-column> -->

          <el-table-column v-if="ssvalue!='单机'" prop="td" width="80" label="今天使用" align="center" :formatter="countFormatter">
          </el-table-column>

          <el-table-column v-if="ssvalue!='单机'"  prop="tt" width="80" label="总计使用" align="center" :formatter="countFormatter">
          </el-table-column>

          <el-table-column :width="btnwidth">
            <template slot="header">
              <el-button type="primary" icon="el-icon-refresh" style="margin-left: 10px;width: 120px"
                         @click="onRefresh">刷新列表
              </el-button>
              <el-button icon="el-icon-plus" type="primary" plain style="margin-left: 18px;width: 120px"
                         @click="AddOfflineDeviceDialog">添加单机
              </el-button>
              <el-button  icon="el-icon-postcard" style="margin-left: 20px;width: 120px"  type="info" plain
                         @click="OfflineDialog=true;OfflineCommited=false">离线充次
              </el-button>
              <el-button type="primary" icon="el-icon-download" style="margin-left: 18px;width: 120px" plain
                         @click="ExportDeviceExcel">导出本页
              </el-button>


            </template>
            <template slot-scope="scope">
  <span >

                <el-button icon="el-icon-edit" style="margin-left: 1px" size="mini"
                           @click="OpenModifyDialog(scope.$index)">修改</el-button>

              </span>



              <span >
                  <el-divider direction="vertical"></el-divider>

                <el-button :disabled="scope.row.mod=='X1'" icon="el-icon-setting" size="mini" @click="OpenConfigDialog(scope.$index)" type="primary" plain
                >配置</el-button>
              </span>

              <span >
                <el-divider direction="vertical"></el-divider>
                <el-button :disabled="scope.row.mod=='X1'" icon="el-icon-sell" size="mini" @click="OpenRechargeDialog(scope.$index)" type="primary" plain
                           >充次</el-button>
              </span>

              <span v-if="pg_list.charge_device">
                                <el-divider direction="vertical"></el-divider>

                <el-button icon="el-icon-bank-card" style="margin-left: 1px" size="mini" type="warning" plain
                           @click="OpenFilterVerifyDialog(scope.$index)">滤芯</el-button>

              </span>

              <span v-if="pg_list.charge_device">

                <el-button icon="el-icon-phone-outline" style="margin-left: 10px" size="mini" type="success" plain
                           @click="OpenServiceDialog(scope.$index)">售后</el-button>

              </span>



              <el-divider direction="vertical"></el-divider>
              <el-button size="mini" @click="OpenMapDialog(scope.$index)"  icon="el-icon-location-outline" plain >定位</el-button>

              <span v-if="pg_list.bind_or_not">
                <el-button icon="el-icon-coordinate" style="margin-left: 5px" size="mini" @click="OpenAllocateDialog(scope.$index,0)"
                           type="info"
                           plain>分配</el-button>
                <!-- 设备分配 Start -->
              
                </span>
              <el-divider direction="vertical"></el-divider>


              <el-button v-if="scope.row.mod!='X1'" size="mini" icon="el-icon-lock"  type="danger"  plain @click="OpenLockDialog(scope.$index)">锁机</el-button>
              <el-popover
                  v-if="scope.row.mod=='X1'"
                  placement="top"
                  width="180"
                  v-model="scope._vis2_">
                <div style="text-align: center; margin: 0">
                  <p>确认删除设备"{{tbdata[scope.$index].na}}"吗？删除后无法恢复</p>

                  <el-button size="mini" icon="el-icon-delete" type="danger" plain @click="DeleteDeviceCommit(scope.$index)">确认删除</el-button>
                </div>
                <el-button  size="mini" icon="el-icon-delete"  type="danger"  plain slot="reference">删除</el-button>
              </el-popover>

              <el-divider direction="vertical"></el-divider>

              <el-popover
                  placement="top"
                  width="220"
                  v-model="scope._vis_export_">
                <div style="text-align: center; margin: 0">
                  <p>导出Excel报表</p>

                  <el-button v-if="scope.row.mod!='X1'" icon="el-icon-tickets"  @click="ExportExcelDialog(scope.$index)"
                             size="mini" >使用记录</el-button>
                  <el-button icon="el-icon-pie-chart"  style="margin-left: 10px"
                             @click="ExportChargeDialog(scope.$index)" size="mini" >充次记录</el-button>
                </div>
                <el-button  size="mini" icon="el-icon-document" type="info" plain slot="reference">导出</el-button>
              </el-popover>


            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex;align-items: center;justify-content:flex-start">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currPage"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="currSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="currTotal">
          </el-pagination>


          <span style="margin-left: 40px">
                列表自动刷新:
              <el-switch style="margin-right: 7px"
                  v-model="autoRefreshFlag"
                         @change="handleSwitchChange"

              >
              </el-switch>
                <span  v-if="autoRefreshFlag">开</span>
                <span v-if="!autoRefreshFlag">关</span>
              </span>

        </div>
      </el-col>



    </el-row>

    <!--  锁机  -->
    <el-dialog title="锁机" :visible.sync="LockDialog" width="500px">
      <el-form :model="Lock">
        <el-form-item label="当前设备:" label-width="100px">
          {{LockName}}
        </el-form-item>

        <el-form-item label="设备编号:" label-width="100px">
          {{LockCid}}
        </el-form-item>

        <el-form-item label="当前状态:" label-width="100px">
          <el-tag
              v-if="!LockID"
              :type="
                    LockStatus === '在线'
                      ? 'success'
                      : LockStatus === '运行'
                      ? 'danger'
                      : 'info'
                  "
              disable-transitions>{{LockStatus}}</el-tag>

          <el-tag
              v-if="LockID"
              type="warning"
              disable-transitions>
            <i class="el-icon-lock"></i>锁机</el-tag>
        </el-form-item>

        <el-form-item label="锁机原因:" label-width="100px">
          <el-input v-model="Lock.reason" autocomplete="off"   maxlength="20"  show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="备注信息:" label-width="100px"   >
          <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入备注信息"
              maxlength="200"  show-word-limit
              v-model="Lock.notes">
          </el-input>
        </el-form-item>
        <el-form-item label="操作人员:" label-width="100px">
          <el-input v-model="Lock.operator" autocomplete="off" maxlength="20"  show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="操作密码:" label-width="100px">
          <el-input v-model="Lock.op_password" autocomplete="off" show-password></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="LockDialog = false;onRefresh()" style="margin-right: 20px">取 消</el-button>
        <el-button type="success" @click="LockCommit(0)">解 锁</el-button>
        <el-button type="danger" @click="LockCommit(1)">锁 定</el-button>
      </div>
    </el-dialog>

    <!-- 充值次数 Start -->
    <el-dialog title="次数充值" :visible.sync="RechargeTimesDialog" width="560px">
                <el-form v-loading="chargeLoading">

<div style="margin-bottom: 20px;text-align: center">
  <b style="color: crimson">当前充次设备：{{ChargeCurr.Name}}  &nbsp;  &nbsp; &nbsp; 充次备编号：{{ChargeCurr.CID}}</b>

</div>
                  <div style="margin-bottom: 20px;text-align: center">
                    <b style="color: crimson">当前状态：{{RechargeInfoSystus}}&nbsp; &nbsp;  |&nbsp; &nbsp;  当前信号：{{ChargeCurr.Rssi}} &nbsp; |&nbsp;
                      <span style="margin-left: 6px">
                飞行模式:
              <el-switch style="margin-right: 7px"
                         v-model="FlyModeFlag"
                         @change="handleFlyModeChange"
                         :disabled="FlyModeDisable"
              >
              </el-switch>
                <span  v-if="FlyModeFlag">开</span>
                <span v-if="!FlyModeFlag&&!FlyModeDisable">关</span>
                <span v-if="FlyModeDisable">(不支持)</span>
              </span></b>
                    <el-alert
                        style="margin-top: 5px"
                        v-if="FlyModeFlag"
                        title="该设备通讯可能存在干扰，已开启飞行模式。充次前请先关闭飞行模式，然后提醒客户断电重新上电，刷新显示在线后再充次，充次完成后重新打开飞行模式。"
                        type="warning"
                        :closable="false">
                    </el-alert>
                  </div >

                  <div style="margin-bottom: 20px;text-align: center">
                    <b style="color: blue">数据上报时间：{{RechargeInfoUpdateTime}}&nbsp; &nbsp;
                      <el-button type="primary" round icon="el-icon-refresh" @click="RefleshChargeInfo">刷新</el-button></b>

                  </div >

                      <el-form :inline="true" :model="OnlineForm" class="demo-form-inline" style="text-align: center">
                        <el-form-item label="当前次数"  label-width="70px">
                          <el-input style="width: 55px"  v-model="OnlineForm.old" :disabled="true"></el-input>
                        </el-form-item>

                        <el-form-item label="充值次数" label-width="70px">
                          <el-input style="width: 55px"  @input	="ChargeChange"  v-model="OnlineForm.edit" ></el-input>
                        </el-form-item>

                        <el-form-item label="充后次数" label-width="70px">
                          <el-input style="width: 55px" v-model="OnlineForm.new" :disabled="true"></el-input>
                        </el-form-item>
                      </el-form>

                      <el-alert
                          v-if="!ChargeCommited"
                          :title="chargedTxt"
                          :type="chargedType"
                          :closable="false">
                      </el-alert>
                      <el-alert
                          v-if="ChargeCommited"
                          title="已发送充次，请和用户确认是否充上，充上了点确认，没充上点再次充次"
                          type="warning"
                          :closable="false">
                      </el-alert>

                      <div style="text-align: center;margin-top: 15px">
                        <el-button v-if="!ChargeCommited" icon="el-icon-position" type="success" @click="RechargeCommit">发送充次</el-button>
                        <el-button v-if="ChargeCommited" icon="el-icon-position" type="warning" @click="RechargeCommit">再次充次</el-button>
                        <el-button :disabled="!ChargeCommited" icon="el-icon-finished" type="success" @click="RechargeConfirmCommit">确认已充次</el-button>
                      </div>
                        <!--  RechargeTimesDialog = false-->
<!--                    <el-tab-pane label="密码充值">-->
<!--                    </el-tab-pane>-->

                </el-form>
              </el-dialog>
    <!-- 充值次数 END -->

    <!-- 离线充次 Start -->
    <el-dialog title="离线充次" :visible.sync="OfflineDialog" width="440px">
      <el-form v-loading="chargeLoading">
          <el-form-item label="离线充次编号"  label-width="120px">
            <el-input
                type="text"
                placeholder="请输入10位离线充次编号"
                v-model="OffCode"
                maxlength="10"
                show-word-limit
            ></el-input>
          </el-form-item>

        <div v-if="OfflineCommited">
          <div style="text-align: center;font-size: 36px">充次密码：{{OffResult}}</div>
          <div style="margin: 10px">充次设备编号：{{OffCid}}</div>
          <div style="margin: 10px">充次客户名称：{{OffName}}</div>
        </div>


        <el-alert
            v-if="!OfflineCommited"
            title="将用户屏幕上的10位<离线充次编号>输入，点击获取密码"
            type="success"
            :closable="false">
        </el-alert>
        <el-alert
            v-if="OfflineCommited"
            :title="OfflineMsg"
            type="warning"
            :closable="false">
        </el-alert>

        <div style="text-align: center;margin-top: 15px">
          <el-button v-if="!OfflineCommited" icon="el-icon-key" type="success" @click="GetOfflineCode">获取密码</el-button>
          <el-button v-if="OfflineCommited" icon="el-icon-key" type="warning" @click="GetOfflineCode">重新获取</el-button>
          <el-button :disabled="!OfflineCommited" style="margin-left: 25px" icon="el-icon-finished" type="success" @click="CommitOfflineCode">确认充上</el-button>
        </div>

      </el-form>
    </el-dialog>
    <!-- 离线充次 END -->

    <!-- 滤芯核销 Start -->
    <el-dialog title="滤芯核销" :visible.sync="FilterVerifyDialog" width="1000px">

      <el-form :inline="true"  class="demo-form-inline">
        <el-form-item label="设备编号："  label-width="90px">
          <el-input style="width: 150px"  v-model="FilterVerifyCID" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="核销客户名称：" label-width="140px">
          <el-input style="width: 150px" v-model="FilterVerifyName" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="搜索待核销条码：" label-width="150px">
          <el-input style="width: 220px" clearable placeholder="输入条码" v-model="FilterVerifySearch" @keyup.enter.native="GetFilter()">
            <!--                <template slot="prepend">查找用户:</template>-->
            <el-button slot="append" @click="GetFilter" icon="el-icon-search">查询</el-button>
          </el-input>        </el-form-item>
      </el-form>
<!--      table-->
      <el-table
          :data="FilterVerifyData"
          border
          stripe
          height="330"
          style="width: 100%; margin: 3px"
      >

        <el-table-column prop="ci" width="160" label="滤芯条码" align="center">
        </el-table-column>

        <el-table-column prop="cd" width="140" label="滤芯批次" align="center">
        </el-table-column>

        <el-table-column prop="s" width="90" label="滤芯状态" align="center">
          <template slot-scope="scope">
            <el-tag
                :type="
                    scope.row.s === '未使用'
                      ? 'success'
                      : scope.row.s === '已使用'
                      ? 'info'
                      : 'danger'
                  "
                disable-transitions>{{scope.row.s}}</el-tag>
          </template>
        </el-table-column>


        <el-table-column prop="ct" width="160" label="创建时间" align="center">
        </el-table-column>

        <el-table-column prop="c" label="备注信息" align="center">
        </el-table-column>


        <el-table-column width="130">
          <template slot-scope="scope" label="操作" >

            <el-popconfirm
                :title="'滤芯码核销后不可撤销，确认将“'+scope.row.ci+'”核销给“'+FilterVerifyName+'”吗？'"
                @confirm="FilterVerifyCommit(scope.$index)"
            >
              <el-button :disabled="scope.row.s == '已使用'"  size="mini" icon="el-icon-finished"  type="primary"  plain slot="reference">核销滤芯</el-button>
            </el-popconfirm>


          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 滤芯核销 END -->


    <!-- 历史记录 Start -->
    <el-dialog  :title="'历史数据查询 : '+HistoryDataName+'  (设备编号 : '+HistoryDataCID+')'" :visible.sync="HistoryDataDialog" width="1000px">

      <el-form :inline="true" class="demo-form-inline" v-loading="HistoryLoading">
        <el-form-item label=" 查询时间">
          <el-date-picker v-model="HistoryTime" type="daterange" align="right" unlink-panels range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="类别：">
          <el-select filterable @change="onRefreshHistory"  v-model="HSvalue" placeholder="请选择" style="width: 150px" >
            <el-option v-for="item in Hselect" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="  ">
          <el-button type="success" @click="onRefreshHistory" icon="el-icon-search">查 询</el-button>
        </el-form-item>

        <el-form-item label="  ">
          <el-button type="primary" @click="ExportHistoryExcel" icon="el-icon-download">导出Excel</el-button>
        </el-form-item>


      </el-form>
      <!--      table-->
      <el-table
          :data="HistoryDataData"
          border
          stripe
          height="550"
          style="width: 100%; margin: 3px"
      >

        <el-table-column prop="time"  label="记录时间" align="center">
        </el-table-column>

        <el-table-column prop="parse" :label="computedLabel" align="center">
        </el-table-column>



      </el-table>
    </el-dialog>
    <!-- 历史记录 END -->


    <!-- 服务记录 Start -->
    <el-dialog :title="'设备名 : '+ServiceName+' —— 售后服务记录   (设备编号 : '+ServiceCID+' / 4G编号 : '+ServiceSN+')'"
               :visible.sync="ServiceDialog" width="1400px">

      <el-row>
        <el-col :span="16">
<!-----------------------------------left--------------------------------------->
          <el-table
              :data="ServiceData"
              border
              stripe
              height="530"
              style="width: 100%; margin: 3px"
          >

            <el-table-column prop="s" width="100" label="售后序号" align="center">
            </el-table-column>

            <el-table-column prop="tt"  label="售后标题" align="center">
            </el-table-column>

            <el-table-column prop="tp" width="110" label="售后分类" align="center">
            </el-table-column>

            <el-table-column prop="st" width="90" label="售后状态" align="center">
              <template slot-scope="scope">
                <el-tag
                    :type="
                    scope.row.st === '待处理'
                      ? 'warn'
                      : scope.row.st === '处理中'
                      ? 'danger'
                      : scope.row.st === '已完成'
                      ? 'success'
                      : 'info'
                  "
                    disable-transitions>{{scope.row.st}}</el-tag>
              </template>
            </el-table-column>


            <el-table-column prop="t" width="160" label="创建时间" align="center">
            </el-table-column>

            <el-table-column width="100">
              <template slot-scope="scope" label="" >
                <el-button size="mini" icon="el-icon-edit" @click="EditService(scope.$index)"  type="primary"  plain>编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
<!-----------------------------------right------------------------------------>
          <el-form ref="form" :disabled="ServiceByID==''" :model="ServiceForm" label-width="80px" >
            <h1 style="margin-left: 20px">售后记录填写 - ({{ServiceNUM}})</h1>
            <el-form-item label="售后标题">
              <el-input clearable v-model="ServiceForm.title"></el-input>
            </el-form-item>
            <el-form-item label="售后分类">
              <el-select v-model="ServiceForm.type" placeholder="请选择售后分类">
                <el-option label="三元催化" value="三元催化"></el-option>
                <el-option label="滤芯" value="滤芯"></el-option>
                <el-option label="蜗牛风机" value="蜗牛风机"></el-option>
                <el-option label="点火" value="点火"></el-option>
                <el-option label="旋转摇摆" value="旋转摇摆"></el-option>
                <el-option label="电源" value="电源"></el-option>
                <el-option label="主板" value="主板"></el-option>
                <el-option label="触摸屏" value="触摸屏"></el-option>
                <el-option label="4G模块" value="4G模块"></el-option>
                <el-option label="语音喇叭" value="语音喇叭"></el-option>
                <el-option label="机械结构" value="机械结构"></el-option>
                <el-option label="热电偶" value="热电偶"></el-option>
                <el-option label="仓温" value="仓温"></el-option>
                <el-option label="参数设置" value="参数设置"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="情况描述">
              <el-input clearable type="textarea" v-model="ServiceForm.problem_desc"></el-input>
            </el-form-item>
            <el-form-item label="售后状态">
              <el-radio-group v-model="ServiceForm.status">
                <el-radio label="待处理"></el-radio>
                <el-radio label="处理中"></el-radio>
                <el-radio label="已完成"></el-radio>
                <el-radio label="已关闭"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="处理方式">
              <el-input clearable type="textarea" v-model="ServiceForm.resolution_desc"></el-input>
            </el-form-item>
            <el-form-item label="售后人员">
              <el-input clearable v-model="ServiceWaiter"></el-input>
            </el-form-item>
            <el-form-item label="售后时间">
              <div style="line-height: 1.5;margin-top: 8px">开始时间：{{ ServiceForm.create_time }}（创建人：{{ServiceForm.creator}}）</div>
              <div style="line-height: 1.5;">处理时间：{{ ServiceForm.process_time }}（处理人：{{ServiceForm.process}}）</div>
              <div style="line-height: 1.5;">完成时间：{{ ServiceForm.completed_time }}（完单人：{{ServiceForm.completer}}）</div>
              <div style="line-height: 1.5;">关闭时间：{{ ServiceForm.closed_time }}（关单人：{{ServiceForm.closer}}）</div>
            </el-form-item>
          </el-form>

        </el-col>
      </el-row>
      <div slot="footer" style="display: flex;justify-content: space-between;" class="dialog-footer">
        <span>
          <el-button icon="el-icon-plus" type="success" @click="CreateServiceOrder">新 建 服 务 单</el-button>
        <el-button style="margin-left: 20px" icon="el-icon-refresh" @click="RefleshServiceList">刷 新</el-button>

        </span>
        <span>
          <el-button icon="el-icon-close" type="info" style="margin-right: 30px" @click="ServiceDialog = false;onRefresh()">关 闭</el-button>
          <el-button icon="el-icon-check" :disabled="ServiceByID==''"  type="primary" @click="ServiceCommit">保 存 记 录</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 服务记录 END -->

    <!-- 修改床名字 Start -->
              <el-dialog :title="'修改设备信息 （设备编号：'+modifyCID+'）'" :visible.sync="modifyDeviceNameDialog" width="500px">
                <el-form>
                  <el-form-item label="客户名" label-width="80px">
                    <el-input clearable v-model="modifyEditor" autocomplete="off" maxlength="16"
                              show-word-limit></el-input>
                  </el-form-item>
                  <el-form-item label="手机号" label-width="80px">
                    <el-input clearable v-model="modifyUserPhone" autocomplete="off" maxlength="15"
                              show-word-limit></el-input>
                  </el-form-item>

                  <el-form-item label="设备区域" label-width="80px">
                    <el-cascader
                        style="width: 380px"
                        size="large"
                        :options="regionData"
                        v-model="modifySelectedArea">
                    </el-cascader>
                  </el-form-item>
                  <el-form-item label="详细地址" label-width="80px">
                    <el-input clearable v-model="modifyAddress" autocomplete="off" maxlength="120"
                              show-word-limit></el-input>
                  </el-form-item>


                  <div style="margin: 10px">设备备注：</div>
                  <el-input
                      type="textarea"
                      placeholder="请输入内容"
                      v-model="modifyComments"
                      :autosize="{ minRows: 4, maxRows: 12 }"
                      maxlength="1000"
                      show-word-limit
                  >
                  </el-input>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="modifyDeviceNameDialog = false">取 消</el-button>
                  <el-button type="primary" @click="ModifyNameAddrCommentCommit">保 存</el-button>
                </div>
              </el-dialog>
                <!-- 修改床名字 END -->

    <!-- 添加单机设备 Start -->
    <el-dialog title="添加单机设备" :visible.sync="addDeviceNameDialog" width="500px">
      <el-form>
        <el-form-item label="设备串号：" label-width="100px" required>
          <el-input clearable v-model="addDeviceSN" autocomplete="off" maxlength="16"
                    show-word-limit>
            <el-button slot="append" type="primary" plain @click="GenSN">随机生成</el-button>
          </el-input>
        </el-form-item>

        <el-form-item label="设备编号：" label-width="100px" required>
          <el-input clearable v-model="addDeviceCID" autocomplete="off" maxlength="16"
                    show-word-limit>
            <el-button slot="append" type="primary" plain @click="GenCID">随机生成</el-button>
          </el-input>
        </el-form-item>

        <el-form-item label="客户名称：" label-width="100px" >
          <el-input clearable v-model="addDeviceName" autocomplete="off" maxlength="16"
                    show-word-limit>
          </el-input>
        </el-form-item>

        <el-form-item label="客户手机：" label-width="100px" >
          <el-input clearable v-model="addDevicePhone" autocomplete="off" maxlength="16"
                    show-word-limit>
          </el-input>
        </el-form-item>

        <el-form-item label="设备区域：" label-width="100px">
          <el-cascader
              style="width: 360px"
              size="large"
              :options="regionData"
              v-model="addSelectedArea">
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址：" label-width="100px">
          <el-input clearable v-model="addAddress" autocomplete="off" maxlength="120"
                    show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="设备备注：" label-width="100px">

        <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="addComments"
            :autosize="{ minRows: 3, maxRows: 6 }"
            maxlength="1000"
            show-word-limit
        >
        </el-input>
        </el-form-item>

      </el-form>

      <span style="padding-left: 9px">☆ 推荐串号和编号字母以'X'开头，和联网设备区分开</span>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addDeviceNameDialog = false">取 消</el-button>
        <el-button type="primary" @click="AddOfflineDeviceCommit">添 加</el-button>
      </div>
    </el-dialog>
    <!-- 添加单机设备 END -->


    <el-dialog title="设备分配" :visible.sync="DeviceAllocateDialog" width="500px">
                <el-form>
                  <el-form-item label="选择设备所属用户" label-width="160px">
                    <el-select v-model="AllocValue" placeholder="请选择">
                      <el-option
                          v-for="item in AllocList"
                          :key="item.uid"
                          :label="item.name"
                          :value="item.uid">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="DeviceAllocateDialog = false">取 消</el-button>
                  <el-button type="primary" @click="AllocateCommit">确 定</el-button>
                </div>
              </el-dialog>
                <!-- 设备分配 END -->

                  <!-- 导出报表 Start -->
              <el-dialog title="导出使用记录" :visible.sync="ExcelDialog" width="500px">

              <div class="block">
                <span style="margin-right: 5px">选择导出时间：</span>
                <el-date-picker
                    v-model="excelTime"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions">
                </el-date-picker>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="ExcelDialog = false">取 消</el-button>
                <el-button type="primary" @click="ExcelCommit">导 出</el-button>
              </div>
              </el-dialog>
              <!-- 导出报表 END -->

<!-- 导出充次记录 +2024年1月29日 Start -->
<el-dialog title="导出充次记录" :visible.sync="ChargeDialog" width="500px">

<div class="block">
  <span style="margin-right: 5px">选择导出时间：</span>
  <el-date-picker
      v-model="excelTime"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      :picker-options="pickerOptions">
  </el-date-picker>
</div>
<div slot="footer" class="dialog-footer">
  <el-button @click="ChargeDialog = false">取 消</el-button>
  <el-button type="primary" @click="ExportChargeCommit">导 出</el-button>
</div>
</el-dialog>
<!-- 导出充次记录 END -->

    <!-- 参数设置 Start -->
    <el-dialog :title="'远程参数设置 : '+configName+'  (设备编号 : '+configCID+')'"
               :visible.sync="configDialog" width="800px">

      <el-row>
        <el-col :span="10" >
          <!-----------------------------------left--------------------------------------->
          <h1 style=" text-align: center">设备控制</h1>
          <el-divider></el-divider>

          <div style="display: flex; flex-direction: column; align-items: center;">
            <span>
               <el-button style="margin: 10px 0;" size="large" icon="el-icon-video-play" type="success" plain @click="DeviceTrun('start')">启动设备</el-button>
            <el-button style="margin: 10px 0 10px 30px;" size="large" icon="el-icon-video-pause" type="danger" plain @click="DeviceTrun('stop')">停止设备</el-button>
            </span>
            <span>
            <el-button style="margin: 10px 0;" size="large" icon="el-icon-download" type="info" plain @click="DeviceTrun('sendsn')">重发编号</el-button>
            <el-button style="margin: 10px 0 10px 30px;" size="large" icon="el-icon-delete" type="info" plain @click="DeviceTrun('reset')">重置注册</el-button>
            </span>
          </div>

          <el-divider></el-divider>
          <h1 style=" text-align: center">设备运行数据</h1>


          <ul>
            <li style="margin: 6px 0;">启动时间：{{runData.work}}</li>
            <li style="margin: 6px 0;">净化温度：{{runData.pt}}</li>
            <li style="margin: 6px 0;">仓内温度：{{runData.uct}}</li>
            <li style="margin: 6px 0;">更新时间：{{runData.time}}</li>
          </ul>
          <span style="font-size: 13px;color: #0570db">运行数据可能存在延迟，实际数值以屏幕显示为准</span>


        </el-col>
        <el-col :span="14">
          <!-----------------------------------right------------------------------------>
          <el-form ref="form" style="border-left: 1px dashed darkgray"  :model="configData" label-width="120px" >
            <h1 style="text-align: center">参数设置</h1>
            <el-divider></el-divider>

            <el-form-item label="工作时间设置:">
              <el-input-number  v-model="configData.wktm" :min="10" :max="90" :step="5"></el-input-number>
              <span style="margin-right: 20px">  </span>
              <el-button  type="primary"  icon="el-icon-check" @click="DeviceConfigCommit('wktm',configData.wktm)">保存</el-button>
            </el-form-item>

            <el-form-item label="仓内温度设置:">
              <el-input-number  v-model="configData.uct" :min="30" :max="61" :step="5"></el-input-number>
              <span style="margin-right: 20px">  </span>
              <el-button  type="primary"  icon="el-icon-check" @click="DeviceConfigCommit('uct',configData.uct)">保存</el-button>
            </el-form-item>


            <el-form-item label="净化温度设置:">
              <el-input-number  v-model="configData.pt" :min="350" :max="500" :step="10"></el-input-number>
              <span style="margin-right: 20px">  </span>
              <el-button  type="primary"  icon="el-icon-check" @click="DeviceConfigCommit('pt',configData.pt)">保存</el-button>
            </el-form-item>


            <el-form-item label="风速设置:">
              <el-input-number  v-model="configData.fs" :min="1" :max="3" :step="1"></el-input-number>
              <span style="margin-right: 20px">  </span>
              <el-button  type="primary"  icon="el-icon-check" @click="DeviceConfigCommit('fs',configData.fs)">保存</el-button>
            </el-form-item>


            <el-form-item label="点火时长设置:">
              <el-input-number  v-model="configData.htm" :min="60" :max="900" :step="10"></el-input-number>
              <span style="margin-right: 20px">  </span>
              <el-button  type="primary"  icon="el-icon-check" @click="DeviceConfigCommit('htm',configData.htm)">保存</el-button>
            </el-form-item>

            <el-form-item label="点火温度设置:">
              <el-input-number  v-model="configData.ht" :min="200" :max="500" :step="10"></el-input-number>
              <span style="margin-right: 20px">  </span>
              <el-button  type="primary"  icon="el-icon-check" @click="DeviceConfigCommit('ht',configData.ht)">保存</el-button>
            </el-form-item>



          </el-form>

        </el-col>
      </el-row>
      <i>提示：所有操作需保证设备处于"在线"或"运行"状态，离线无法远程配置。</i>
      <div slot="footer" style="display: flex;justify-content: space-between;" class="dialog-footer">
        <span>
          <el-button icon="el-icon-refresh" type="success" @click="RefleshConfig">刷 新</el-button>

          <el-button v-if="pg_list.history_data" icon="el-icon-tickets" type="primary" @click="OpenHistoryDataDialog">历史数据</el-button>

        </span>
        <span>
          <el-button icon="el-icon-close" type="info"  @click="configDialog = false;onRefresh()">关 闭</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 参数设置 END -->

    <el-dialog :title="'设备定位 - '+MapTitle+ '       -->> 地址: '+MapAddress" :visible.sync="MapDialog" width="1300px">
      <el-form :inline="true" >


        <el-form-item >
          <el-radio-group v-model="MapSelect"  @change="GetMapInfo">
            <el-radio-button  label="设备定位" ></el-radio-button>
            <el-radio-button label="标注定位"></el-radio-button>
            <el-radio-button  label="手动标定位置"></el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item  label="省市区域：">
          <el-cascader
              style="width: 210px"
              size="large"
              :options="regionData"
              v-model="mapSelectedArea">
          </el-cascader>
        </el-form-item>

        <el-form-item  label="地址：">
          <el-input clearable style="width: 160px" v-model="MapAddressInput" placeholder="详细地址"></el-input>
        </el-form-item>

        <el-form-item vlabel="经纬度：">
          <el-input clearable style="width: 150px"  v-model="MapMarked" placeholder="输入坐标"></el-input>
        </el-form-item>

        <el-form-item  >
          <el-button type="primary" @click="LocationMarkCommit">保存</el-button>
        </el-form-item>
      </el-form>
      <div class="block" style="border-top: 1px dashed #000;">
        <iframe
            :src="MapURL"
            frameborder="0"
            style="width: 100%; height: 650px;"
        ></iframe>
      </div>
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-select @change="MapGenUrl" style="margin-right: 30px" v-model="MapType" placeholder="地图类型">-->
<!--          <el-option-->

<!--              v-for="item in MapTypeoptions"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--        <el-button type="primary" @click="MapDialog = false">关 闭</el-button>-->
<!--      </div>-->
    </el-dialog>
<!--    地图END-->
  </div>
</template>

<script>
import {MessageBox} from "element-ui";
import {codeToText, regionData} from 'element-china-area-data'

export default {
  data() {
    return {
      autoRefreshFlag: false, // 默认关闭自动刷新
      refreshTimer: null, // 定时器
      //page
      currPage:1,
      currSize:10,
      currTotal:0,
      ordercheck:true,
      SNorCID:"cid",
      searchinput: "", // 按设备名查找
      searchsn:"",//按SN查找
      searchphone:"",//按手机号查找
      leinput:"",// 按剩余次数查找
      tbdata: [
        ],
      // uselect: [{
      //   value: '',
      //   label: '不限'
      // }],
      // usvalue: '',
      sselect: [{
        value: '',
        label: '不限'
      }, {
        value: '在线',
        label: '在线'
      }, {
        value: '运行',
        label: '运行'
      }, {
        value: '离线',
        label: '离线'
      }, {
        value: '飞行模式',
        label: '飞行模式'
      }, {
        value: '单机',
        label: '单机'
      }],
      // , {
    //   value: '禁用',
    //   label: '禁用'
    // }
      ssvalue: '',


      // 地图
      MapDialog:false,
      MapIsMarked:false,
      MapTitle:'',
      MapURL:'',
      MapLoc:{},
      MapTypeoptions: [{
        value: 'a',
        label: '高德地图'
      }, {
        value: 't',
        label: '腾讯地图(备用)'
      },
      ],
      MapSelect:'设备定位',
      MapSN:'',
      MapMarked:'',
      MapAddressInput:"",
      MapAddress:'',
      MapType: 'a',
      mapSelectedArea: [],

      // 充次前
      FlyModeFlag:false,
      FlyModeDisable:true,
      RechargeInfoCount:0,
      RechargeInfoSystus:"",
      RechargeInfoFly:"",
      RechargeInfoUpdateTime:"",
      // 充次
      RechargeTimesDialog: false,
      RechargeEditor: "20",
      RechargeSN: "",
      RechargeComfirmOid:"",
      chargeLoading:false,
      chargedTxt:"充次前请与客户沟通，将屏幕切换到主界面（能看到剩余次数）",
      chargedType:"success",
      OnlineForm:{
        old:"",
        edit:"",
        new:"",
      },
      ChargeCurr:{
        Name:"",
        Status:"",
        Rssi:""
      },
      // 离线充次
      OfflineDialog: false,
      OfflineCommited:false,
      OfflineMsg:"",//
      OffCode:'',
      OffResult:'',
      OffOid:'',
      OffCid:'',
      OffName:'',

      ChargeCommited:false,
      // 滤芯核销
      FilterVerifyDialog: false,
      FilterVerifySN: "",
      FilterVerifyCID: "",
      FilterVerifyName:"",
      FilterVerifyData: [],
      FilterVerifySearch:"",
      // 售后记录
      ServiceDialog: false,
      ServiceSN: "",
      ServiceCID: "",
      ServiceName:"",
      ServiceData: [],
      ServiceByID:"",
      ServiceNUM:"",
      ServiceForm:{},
      ServiceWaiter:"",
      // 远程配置
      configDialog:false,
      configSN: "",
      configCID:"",
      configName:"",
      configData:{},
      runData:{},
      // 历史风机参数
      HistoryDataDialog: false,
      HistoryDataSN: "",
      HistoryDataCID: "",
      HistoryDataName:"",
      HistoryLoading:false,
      HistoryDataData: [],
      Hselect: [{
        value: 'heat',
        label: '加热温度'
      }, {
        value: 'pulse',
        label: '风机脉冲'
      }],
      // , {
      //   value: '禁用',
      //   label: '禁用'
      // }
      HSvalue: 'heat',
      HistoryTime:null,
      HistoryHeader:
          {
            time: '记录时间',
            parse: 'XXX',
          },
      // FilterVerifyDialog: false,
      // FilterVerifySN: "",
      // FilterVerifyCID: "",
      // FilterVerifyName:"",
      // FilterVerifyData: [],
      // FilterVerifySearch:"",

      // add device
      addDeviceNameDialog: false,
      addDeviceSN: "",
      addDevicePhone: "",
      addDeviceName: "",
      addDeviceCID: "",
      addComments: "",
      addAddress:"",
      addSelectedArea: [],


      // 修改名字
      modifyDeviceNameDialog: false,
      modifyEditor: "",
      modifyUserPhone: "",
      modifyComments:"",
      modifySN: "",
      modifyCID: "",
      modifyAddress:"",
      regionData,
      modifySelectedArea: [],
      // 设备分配
      DeviceAllocateDialog: false,
      AllocSN: "",
      AllocValue: "",
      AllocList: null,

      // 锁机
      LockDialog: false,
      LockSN: "",
      LockName: "",
      LockStatus: "",
      LockID: "",
      LockCid: "",
      Lock: {
        reason:"",
        notes:"",
        operator:"",
        op_password:""
      },
      LockList: [{
        value: '1',
        label: '未授权使用'
      }, {
        value: '2',
        label: '您已欠费'
      }, {
        value: '3',
        label: '请补齐分期尾款'
      }, {
        value: '4',
        label: '设备维护中（其他原因）'
      }],

      // 导出报表
      ExcelDialog: false,
      excelTime: "",
      ExcelSN: "",
      ExcelCID: "",
      ExcelName: "",
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      orderHeader: {
        i: '序号',
        s: '启动时间',
        e: '停止时间',
        u: '工作时长',
        m: '备注',
      },
      orderList: [],
      //device list excel
      deviceHeader:
          {
            sn: 'SN',
            na: '客户名称',
            ph: '手机号',
            st: '状态',
            cnt: '剩余次数',
            fc: '滤芯更换',
            cc: '充次计数',
            svt: '售后次数',
            td: '今日次数',
            tt: '总计次数',
          },

      // 导出充次 + 2024年1月29日
      ChargeDialog: false,
      chargeorderHeader: {
        i: '系统单号',
        op: '操作者',
        type: '操作类型',
        status: '状态',
        before: '充值前次数',
        change: '充值后次数',
        create_time: '操作时间',
        transaction_id: '滤芯编号/离线编号',
        comments: '备注信息',
      },
      chargeOrderList: [],

      enen: false,
      pg_list: {
        charge_device: false,
        rename_device: false,
        enable_device: false,
        bind_or_not: false,
        device_order_export: false,
        charge_order_export: false,
        infinite_times: false,
        delete_device: false,
        history_data:false,
      },
      btnwidth:660,
    }
  },
  computed: {
    computedLabel() {
      // 根据HSvalue的值找到对应的label
      const selectedOption = this.Hselect.find(option => option.value === this.HSvalue);
      return selectedOption ? selectedOption.label : '';
    }
  },
  created() {
    this.info = this.$store.state.userInfo

    // this.onGetVUList();
    this.PG_Query();
    this.onRefresh();

  },
  mounted() {
    if (this.autoRefreshFlag) {
      this.startRefreshTimer();
    }
  },
  beforeDestroy() {
    this.clearRefreshTimer();
  },
  methods: {
    MessageBox,
    handleSwitchChange(value) {
      if (value) {
        this.startRefreshTimer();
      } else {
        this.clearRefreshTimer();
      }
    },
    startRefreshTimer() {
      this.clearRefreshTimer(); // 清除之前的定时器
      this.refreshTimer = setInterval(() => {
        this.onRefresh();
      }, 5000); // 每5秒刷新一次
    },
    clearRefreshTimer() {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
        this.refreshTimer = null;
      }
    },
    // 启动设备
    async DeviceTrun( turn) {
      let res = await this.$api.operator_device({
        sn: this.configSN+"",
        turn: turn+"",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
      }
    },
    // 导出报表
    ExportExcelDialog(index) {
      this.ExcelSN = this.tbdata[index].sn
      this.ExcelCID = this.tbdata[index].cid
      this.ExcelName = this.tbdata[index].na
      this.ExcelDialog = true
    },
    
    async ExcelCommit() {
      let res = await this.$api.device_order_export({
        sn: this.ExcelSN,
        start: this.excelTime[0],
        end: this.excelTime[1]
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '导出成功',
          type: 'success',
        })
        this.orderList = res.data

        this.ExportOrderExcel()
      }

    },

    //////////////++++++++++++++////////// order excel //++++++++++++++++++++++//////////////////

    async ExportOrderExcel() {  //TODO V2
      console.log('ExportOrderExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name = "设备使用记录-" + this.ExcelName +'-'+this.ExcelCID+ '(' + this.excelTime[0] + '-' + this.excelTime[1] + ').xlsx'
      let table = JSON.parse(JSON.stringify(this.orderList))
      let map = []
      let cols = Object.keys(this.orderHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.orderHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.orderHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 120
      }
      widthMap['A'] = 60
      widthMap['E'] = 90

      let colConf = this.$Excel.makeColConfig({...widthMap}, 120)
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: 'ebfade'}},
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
          }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: '67c23a'}},
              font: {color: {rgb: 'ffffff'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            return currentRow === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    }, //end order excel
    // ------------------------------------------------------------------------------------------------------------
     
    // 导出充次记录 + 2024年1月29日
     ExportChargeDialog(index) {
       this.ExcelSN = this.tbdata[index].sn
       this.ExcelCID = this.tbdata[index].cid
       this.ExcelName = this.tbdata[index].na
       this.ChargeDialog = true
    },

    async ExportChargeCommit() {  // + 2024年1月29日

      let res = await this.$api.charge_order_export({
        sn: this.ExcelCID,
        start: this.excelTime[0],
        end: this.excelTime[1]
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '导出成功',
          type: 'success',
        })
        this.chargeOrderList = res.data

        this.ExportChargeOrderExcel()
      }

    },

    async ExportChargeOrderExcel() {  // + 2024年1月29日
      console.log('ExportChargeOrderExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name = "设备充次记录-" + this.ExcelName + '(' + this.excelTime[0] + '-' + this.excelTime[1] + ').xlsx'
      let table = JSON.parse(JSON.stringify(this.chargeOrderList))
      let map = []
      let cols = Object.keys(this.chargeorderHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.chargeorderHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.chargeorderHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 80
      }
      widthMap['A'] = 120
      widthMap['B'] = 90
      widthMap['C'] = 80
      widthMap['G'] = 110
      widthMap['H'] = 120
      widthMap['I'] = 200

      let colConf = this.$Excel.makeColConfig({...widthMap}, 110)
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: 'ebfade'}},
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
          }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: '107c41'}},
              font: {color: {rgb: 'ffffff'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            return currentRow === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    }, //end order excel
    // ------------------------------------------------------------------------------------------------------------
     
    
    // 启用禁用设备

    // async UnlockCommit(index) {
    //   let op = localStorage.getItem('op');
    //   if (op === null || op === '') {
    //     op = this.info.name;
    //     localStorage.setItem('op', op);
    //   }
    //   let res = await this.$api.lock_device({
    //     sn: this.tbdata[index].sn+"",
    //     op:op,
    //     code:"0",
    //     reason:"后台解锁",
    //   })
    //   if (res.status == 1) {
    //     this.$message({
    //       message: '操作成功',
    //       type: 'success',
    //     })
    //     this.onRefresh()
    //   }
    // },
    // async EnableDisableDevice(index, en) {
    //   let res = await this.$api.enable_device({
    //     sn: this.tbdata[index].sn,
    //     en: en,
    //   })
    //   console.log(res)
    //   if (res.status == 1) {
    //     this.$message({
    //       message: '操作成功',
    //       type: 'success',
    //     })
    //     this.onRefresh();
    //   }
    // },
    // 充次弹窗
    ChargeChange(e){
      this.OnlineForm.new = parseInt(this.OnlineForm.old) + parseInt(e)
    },
    // 地图
    async OpenMapDialog(index) {
      this.MapURL = ''
      this.MapTitle = this.tbdata[index].na
      this.MapSN = this.tbdata[index].sn
      this.MapMarked = ''
      this.MapAddressInput = ""
      let res = await this.$api.get_location({
        type: 'auto',///this.MapSelect,
        sn: this.MapSN
      })
      console.log(res)
      if (res.status == 1) {
        this.MapURL = res.data.url
        this.MapAddress = res.data.addr
        this.MapSelect = res.data.type
        this.MapAddressInput = res.data.address
        this.mapSelectedArea = res.data.area
        this.MapMarked = res.data.marked
      }
      this.MapDialog = true
    },
    async GetMapInfo() {
      this.MapURL = ''
      this.MapMarked = ''
      this.MapMarked = ''
      this.MapAddressInput = ""
      let res = await this.$api.get_location({
        type: this.MapSelect,
        sn: this.MapSN
      })
      console.log(res)
      if (res.status == 1) {
        this.MapURL = res.data.url
        this.MapAddress = res.data.addr
        this.MapSelect = res.data.type
        this.MapAddressInput = res.data.address
        this.mapSelectedArea = res.data.area
        this.MapMarked = res.data.marked
      }
    },
    async LocationMarkCommit(){
      let res = await this.$api.location_commit({
        sn: this.MapSN+"",
        mark:this.MapMarked+"",
        area:this.mapSelectedArea,
        area_name:[codeToText[this.mapSelectedArea[0]],codeToText[this.mapSelectedArea[1]],codeToText[this.mapSelectedArea[2]]],
        address:this.MapAddressInput+"",
      })
      console.log(res)
      if (res.status == 1) {
        this.$notify({
          title: '成功',
          message: '标注成功',
          type: 'success'
        });
        this.MapSelect='标注定位'
        this.GetMapInfo()
      }
    },
    // MapGenUrl(){
    //     if(this.MapType == "t"){
    //       this.MapURL = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${this.MapLoc.lat},${this.MapLoc.lng};title:${this.MapTitle}`
    //     } else  if(this.MapType == "a"){
    //       this.MapURL = `https://uri.amap.com/marker?position=${this.MapLoc.lng},${this.MapLoc.lat}&name=${this.MapTitle}&coordinate=wgs84&callnative=0`
    //     }else  if(this.MapType == "b"){
    //       this.MapURL = `http://api.map.baidu.com/marker?location=${this.MapLoc.lat},${this.MapLoc.lng}&title=${this.MapTitle}&output=html`
    //     }else {
    //       this.MapURL = `https://uri.amap.com/marker?position=${this.MapLoc.lng},${this.MapLoc.lat}&name=${this.MapTitle}&coordinate=wgs84&callnative=0`
    //     }
    // },
    async handleFlyModeChange(value) {
      if (value) {
        console.log("turn on")
        await this.$api.turn_flymode({
          sn: this.RechargeSN,
          turn:"fly"
        })
      } else {
        console.log("turn off")
        await this.$api.turn_flymode({
          sn: this.RechargeSN,
          turn:"off"
        })
      }
    },
    async RefleshChargeInfo() {
      let res = await this.$api.charge_info({
        sn: this.RechargeSN
      })
      console.log(res)
      if (res.status == 1) {
        this.RechargeInfoCount = res.data.counts
        this.RechargeInfoSystus = res.data.sys_status
        this.RechargeInfoFly = res.data.fly
        if(this.RechargeInfoFly == "fly"){
          this.FlyModeFlag = true
        }else{
          this.FlyModeFlag = false
        }
        if (this.RechargeInfoFly == "x"){
          this.FlyModeDisable = true
        }else{
          this.FlyModeDisable = false
        }
        this.RechargeInfoUpdateTime = res.data.time
        this.OnlineForm.old = this.RechargeInfoCount
        // this.OnlineForm.new = parseInt(this.OnlineForm.old) + 55
      }
    },
    OpenRechargeDialog(index) {

      this.ChargeCurr.Name = this.tbdata[index].na
      this.ChargeCurr.CID = this.tbdata[index].cid
      this.ChargeCurr.Status = this.tbdata[index].st
      this.OnlineForm.old = this.tbdata[index].cnt
      this.OnlineForm.edit = 55
      this.OnlineForm.new = parseInt(this.OnlineForm.old) + 55
      this.RechargeSN = this.tbdata[index].sn
      this.RechargeTimesDialog = true
      this.ChargeCommited = false
      this.chargedTxt = "充次前请与客户沟通，将屏幕切换到主界面（能看到剩余次数,方便确认充上）"
      this.chargedType = "success"

      this.RefleshChargeInfo()

      let r = parseInt(this.tbdata[index].r, 10);
      if (r > 26) {
        this.ChargeCurr.Rssi = '极好';
      } else if (r >= 20 && r <= 26) {
        this.ChargeCurr.Rssi = '较好';
      } else if (r >= 15 && r <= 20) {
        this.ChargeCurr.Rssi = '一般';
      } else if (r >= 11 && r <= 14) {
        this.ChargeCurr.Rssi = '较差';
      } else {
        this.ChargeCurr.Rssi = '极差';
      }
      // this.ChargeCurr.Rssi = this.ChargeCurr.Rssi+ ' (信号值='+this.tbdata[index].r+')';

      if (this.tbdata[index].st == "离线") {
        this.ChargeCurr.Rssi = '无信号（或关机）';
        this.$message({
          message: '4G充次时需确保设备在线，请等待设备上线后刷新或使用密码充次',
          type: 'warning'
        });
      }
    },

    OpenFilterVerifyDialog(index) {
      this.FilterVerifyName = this.tbdata[index].na
      this.FilterVerifySN = this.tbdata[index].sn
      this.FilterVerifyCID = this.tbdata[index].cid
      this.FilterVerifyDialog = true
    },

    OpenConfigDialog(index){
      this.configName = this.tbdata[index].na
      this.configSN = this.tbdata[index].sn
      this.configCID = this.tbdata[index].cid
      this.RefleshConfig();
      this.configDialog = true
    },
    OpenHistoryDataDialog(){
      this.configDialog = false
      this.HistoryDataDialog = true
      this.HistoryDataSN =  this.configSN
      this.HistoryDataCID = this.configCID
      this.HistoryDataName = this.configName
    },
    async onRefreshHistory(){
      this.HistoryLoading = true
      this.HistoryDataData = []
      try {
        let res = await this.$api_log.heat_pulse({
          sn: this.HistoryDataSN+"",
          type:this.HSvalue,
          start: this.HistoryTime[0]+"",
          end: this.HistoryTime[1]+"",
          reverse:false,
          db:"latest",
          access:"xxx"
        })
        this.HistoryLoading = false

        // console.log(res)
        if (res.status == 1) {
          this.HistoryDataData = res.data
        }
      }catch (error) {
        this.HistoryLoading = false
      }
    },


    async OpenLockDialog(index){
      this.LockSN = this.tbdata[index].sn
      this.LockCid = this.tbdata[index].cid
      this.LockName = this.tbdata[index].na
      this.LockStatus = this.tbdata[index].st //get

      this.Lock.operator = "" ;
      this.Lock.reason="" //get
      this.Lock.notes="" //get
      this.Lock.LockID=0 //get
      let res = await this.$api.get_lock_info({
        sn: this.LockSN,
      })
      console.log(res)
      if (res.status == 1) {
        this.LockID = res.lock_id //get
        this.Lock.reason=res.lock_js.Reason //get
        this.Lock.notes=res.lock_js.Notes //get
        this.Lock.operator = res.lock_js.Operator
      }
      this.Lock.op_password=""
      this.LockDialog = true
    },
    // 锁机
    async LockCommit(en) {
      let res = await this.$api.lock_device({
        op:this.Lock.operator+"",
        sn: this.LockSN+"",
        uid:""+this.info.uid,
        en: en+"",
        reason:this.Lock.reason+"",
        op_password:this.Lock.op_password+"",
        notes:this.Lock.notes+"",
      })
      if (res.status == 1) {
        this.$alert(res.msg, '操作成功', {
          confirmButtonText: '确定',
          type: 'success'
        });
      }
    },

    OpenServiceDialog(index){
      this.ServiceName = this.tbdata[index].na
      this.ServiceSN = this.tbdata[index].sn
      this.ServiceCID = this.tbdata[index].cid
      this.ServiceByID = ""
      this.RefleshServiceList();
      this.ServiceDialog = true
    },

    async CreateServiceOrder() {
      let op = localStorage.getItem('op');
      if (op === null || op === '') {
        op = this.info.name;
        localStorage.setItem('op', op);
      }
      let res = await this.$api.create_server_order({
        sn: this.ServiceSN,
        op:op,
      })
      if (res.status == 1) {
        this.RefleshServiceList()
      }
    },
    async EditService(index) {
      this.ServiceByID = this.ServiceData[index].id
      this.ServiceNUM = this.ServiceData[index].s

      let res = await this.$api.service_detail({
        id:this.ServiceByID
      })
      console.log(res)
      if (res.status == 1) {
        console.log(res.data)
        this.ServiceForm = res.data

        if(this.ServiceWaiter == "")
        {
          this.ServiceWaiter = localStorage.getItem('op');
          if (this.ServiceWaiter === null || this.ServiceWaiter === '') {
            this.ServiceWaiter = this.info.name;
          }
        }
        localStorage.setItem('op', this.ServiceWaiter);
      }
    },
    /////////////////////////////////////////// excel //////////////////////////////////////////////////
    ExportHistoryExcel(){
      console.log('exportExcel() ')
      const selectedOption = this.Hselect.find(option => option.value === this.HSvalue);
      this.HistoryHeader.parse = selectedOption.label
      //let dev = window.sessionStorage.getItem('devname')
      let formattedDate = this.HistoryTime[0]+"~"+ this.HistoryTime[1]
      let name = this.HistoryDataName+"("+this.HistoryDataCID+")"+this.HistoryHeader.parse+"历史记录"  + formattedDate + '.xlsx';
      let table = JSON.parse(JSON.stringify(this.HistoryDataData))
      let map = []
      let cols = Object.keys(this.HistoryHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.HistoryHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.HistoryHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 70
      }
      widthMap['A'] = 200
      widthMap['B'] = 120

      let colConf = this.$Excel.makeColConfig({...widthMap}, 70)
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: 'ebfade'}},
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
          }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: '67c23a'}},
              font: {color: {rgb: 'ffffff'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            return currentRow === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    },
    ExportDeviceExcel() {
      console.log('exportExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let currentDate = new Date();
      let formattedDate = currentDate.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年') + '日';
      let name = "设备列表导出-"  + formattedDate + '.xlsx';
      let table = JSON.parse(JSON.stringify(this.tbdata))
      let map = []
      let cols = Object.keys(this.deviceHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.deviceHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.deviceHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 70
      }
      widthMap['A'] = 100
      widthMap['B'] = 120
      widthMap['C'] = 120

      let colConf = this.$Excel.makeColConfig({...widthMap}, 70)
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: 'ebfade'}},
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
          }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: '67c23a'}},
              font: {color: {rgb: 'ffffff'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            return currentRow === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    },
    async CommitOfflineCode(){
      let res = await this.$api.offline_confirm({
        cid: this.OffCid + "",
        oid: this.OffOid+"",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
        this.OfflineDialog = false
        this.onRefresh()
      }
    },
    async GetOfflineCode(){
      let op = localStorage.getItem('op');
      if (op === null || op === '') {
        op = this.info.name;
        localStorage.setItem('op', op);
      }
      let res = await this.$api.offline_charge_record({
        offcode: this.OffCode + "",
        op:op,
      })
      console.log(res)
      if (res.status == 1) {
        this.OffResult = res.code
        this.OffOid = res.oid
        this.OffCid = res.cid
        this.OffName = res.name
        this.OfflineMsg = res.msg
        this.OfflineCommited = true
      }
    },
    // 确认充次
    async RechargeConfirmCommit() {
      let res = await this.$api.charge_confirm({
        sn: this.RechargeSN+"",
        oid: this.RechargeComfirmOid+"",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
        this.RechargeTimesDialog = false
        this.onRefresh()
      }
    },
    // 充次
    async RechargeCommit() {
      this.chargeLoading = true
      let op = localStorage.getItem('op');
      if (op === null || op === '') {
        op = this.info.name;
        localStorage.setItem('op', op);
      }
      try {
        let res = await this.$api.charge_device({
          sn: this.RechargeSN + "",
          op:op,
          times: this.OnlineForm.new + "",
        })
        console.log(res)
        this.chargeLoading = false
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.RechargeComfirmOid = res.oid
          this.ChargeCommited = true
        }
      }catch (error) {
        this.chargedType="error"
        this.chargedTxt="充次失败，建议检查设备在线和信号情况，并点击“再次充次”"
        this.chargeLoading = false;
        }
    },
    // 新建单机设备
    AddOfflineDeviceDialog(){
      this.addDevicePhone = ""
      this.addDeviceSN = ""
      this.addDeviceCID = ""
      this.addDeviceName = ""
      this.addComments = ""
      this.addAddress = ""
      this.addSelectedArea = []
      this.addDeviceNameDialog = true
    },
    async AddOfflineDeviceCommit() {
      let res = await this.$api.add_offline_device({
        sn: this.addDeviceSN,
        cid: this.addDeviceCID,
        name: this.addDeviceName,
        phone: this.addDevicePhone,
        comm: this.addComments,
        area: this.addSelectedArea,
        area_name: [codeToText[this.addSelectedArea[0]], codeToText[this.addSelectedArea[1]], codeToText[this.addSelectedArea[2]]],
        address: this.addAddress,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '设备添加成功',
          type: 'success',
        })
        this.onRefresh();
      }
      // this.modifyDeviceNameDialog = false
    },
    // 修改名字
    async OpenModifyDialog(index) {
      this.modifyEditor = this.tbdata[index].na
      this.modifyUserPhone = this.tbdata[index].ph
      this.modifySN = this.tbdata[index].sn
      this.modifyCID = this.tbdata[index].cid
      this.modifyComments = ""
      this.modifyAddress = ""
      let res = await this.$api.get_comments_and_addr({
        sn: this.modifySN,
      })
      console.log(res)
      if (res.status == 1) {
        this.modifyComments = res.comments
        this.modifyAddress = res.addr.address
        this.modifySelectedArea = res.addr.area
      }
      this.modifyDeviceNameDialog = true
    },
    async ModifyNameAddrCommentCommit() {
      let res = await this.$api.edit_device_info({
        sn: this.modifySN,
        rename: this.modifyEditor,
        phone: this.modifyUserPhone,
        comm:this.modifyComments,
        area:this.modifySelectedArea,
        area_name:[codeToText[this.modifySelectedArea[0]],codeToText[this.modifySelectedArea[1]],codeToText[this.modifySelectedArea[2]]],
        address:this.modifyAddress,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.modifyDeviceNameDialog = false
    },
    // 设备分配


    async OpenAllocateDialog(index) {
      this.AllocSN = this.tbdata[index].sn

      let res = await this.$api.device_owner({
        sn:this.AllocSN
      })
      if (res.status == 1) {
          this.AllocList = res.data
          this.AllocValue = res.uid
      }
      else
      {
         this.AllocValue =""
      }
      this.DeviceAllocateDialog = true
    },
    async AllocateCommit() {
      let res = await this.$api.bind_or_not({
        sn: this.AllocSN,
        uid: this.AllocValue,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.DeviceAllocateDialog = false
    },

    async FilterVerifyCommit(index) {
      let op = localStorage.getItem('op');
      if (op === null || op === '') {
        op = this.info.name;
        localStorage.setItem('op', op);
      }
      let res = await this.$api.filter_verify({
        ci:this.FilterVerifyData[index].ci+"",
        sn: this.FilterVerifyCID,
        op:op,

      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '核销成功',
          type: 'success',
        })
        this.GetFilter();
      }
    },


    // 主刷新
    async onRefresh() {
      let res = await this.$api.device_list({
        name: this.searchinput+"",
        status: this.ssvalue+"",
        sn:this.searchsn+"",
        sn_type:this.SNorCID+"",
        phone: this.searchphone+"",
        le:this.leinput+"",
        p: this.currPage+"",
        z:this.currSize+"",
        sort:this.ordercheck,
      })
      console.log(res)
      if (res.status == 1) {
        this.tbdata = res.data
        if(this.currTotal != res.total){
          this.currPage = 1
          this.currTotal = res.total // *2024年12月18日
          this.onRefresh()
        }
        
        // if (this.ssvalue == "禁用") {
        //   this.enen = true
        // } else {
        //   this.enen = false
        // }
      }
    },
     async GetFilter() {
      let res = await this.$api.coupon_list({
        s: this.FilterVerifySearch+"",
        t: "",
        p: "1",
        z: "30",
        by:"id"
      })
      // console.log(res)
      if (res.status == 1) {
        this.FilterVerifyData = res.data
      }
    },
    async RefleshConfig(){
      let res = await this.$api.device_config({
        sn: this.configSN+"",
      })
      console.log(res)
      if (res.status == 1) {
        this.configData = res.data
        this.runData = res.run
      }
    },
    async DeviceConfigCommit(k,v) {
      console.log("commit",k+"",v+"")
      let op = localStorage.getItem('op');
      if (op === null || op === '') {
        op = this.info.name;
        localStorage.setItem('op', op);
      }
      let res = await this.$api.device_config_commit({
        key:k+"",
        val:v+"",
        sn:this.configSN,
        op:op,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
      }
    },
    async RefleshServiceList(){
      let res = await this.$api.service_list({
        sn: this.ServiceSN+"",
      })
      // console.log(res)
      if (res.status == 1) {
        this.ServiceData = res.data


      }
    },
    async ServiceCommit() {
      let res = await this.$api.service_commit({
        data:this.ServiceForm,
        id:this.ServiceByID,
        op:this.ServiceWaiter,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
        this.RefleshServiceList();
      }
    },

    // 获取页面上可用的按钮
    async PG_Query() {
      let res = await this.$api.pg_query({
        pg: this.pg_list,
      })

      // console.log("pg ->", res)
      if (res.status == 1) {
        this.pg_list = res.data
      }

      console.log("====>",this.pg_list)

      let btnNum = 0
      for (let k in this.pg_list)
        if (this.pg_list[k])
          btnNum++

      this.btnwidth = 400

      if (this.pg_list.history_data){
        btnNum = btnNum - 1
      }

      if (btnNum >= 4){
        this.btnwidth = this.btnwidth + (btnNum-3)*90
      }


    },
    async DeleteDeviceCommit(index) {
      let res = await this.$api.delete_device({
        sn: this.tbdata[index].sn+""
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '删除成功',
          type: 'success',
        })
        this.onRefresh();
      }
    },
    handleSizeChange(val) {
      this.currSize = val
      this.currPage = 1
      this.onRefresh()
    },
    handleCurrentChange(val) {
      this.currPage = val
      this.onRefresh()
    },
    temperatureFormatter(row, column, cellValue) {
    return cellValue + '℃';
    },
    countFormatter(row, column, cellValue) {
      return cellValue + '次';
    },
    GenSN(){
      const timestamp = Date.now().toString().slice(-10);
      const randomNum = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
      this.addDeviceSN = `X${timestamp}${randomNum}`;
    },
    GenCID(){
      const timestamp = Date.now().toString().slice(-10, -6 );
      const randomNum = Math.floor(Math.random() * (999 - 100 + 1)) + 100;
      this.addDeviceCID = `X${timestamp}${randomNum}`;
    },
  }
}
</script>

<style scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.detail-card .el-button {
  margin: 8px;
  margin-bottom: 14px;
  font-size: 16px;
  width: 92%
}

</style>
