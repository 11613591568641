<template>
  <div class="usercomp">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" class="demo-form-inline">

            <el-form-item label="按滤芯状态筛选：" label-width="130px">
              <el-select v-model="idtvalue" @change="onRefresh" placeholder="请选择">
                <el-option
                    v-for="item in idtselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 20px" label="  按条码/名称查找:" label-width="140px">
              <el-select  v-model="searchvalue" placeholder="请选择">
                <el-option
                    v-for="item in searchselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  label="">
              <el-input clearable placeholder="输入条码/名称" v-model="searchinput" @keyup.enter.native="onRefresh()">
<!--                <template slot="prepend">查找用户:</template>-->
                <el-button slot="append" @click="onRefresh" icon="el-icon-search">查询</el-button>
              </el-input>
            </el-form-item>

            <el-button
                icon="el-icon-plus"
                style="margin-left: 30px;width: 180px"
                @click="CreateNewCode"
            >单个生成滤芯码
            </el-button>
            <el-button
                icon="el-icon-magic-stick"
                style="margin-left: 30px;width: 180px"
                @click="CreateMultiCode"
            >批量生成滤芯码
            </el-button>
          </el-form>

        </div>


        <el-table
            :data="tbdata"
            border
            stripe
            height="600"
            style="width: 100%; margin: 3px"
        >

          <el-table-column prop="ci" width="160" label="滤芯条码" align="center">
          </el-table-column>

          <el-table-column prop="cd" width="165" label="滤芯批次" align="center">
          </el-table-column>

          <el-table-column prop="s" width="90" label="滤芯状态" align="center">
            <template slot-scope="scope">
              <el-tag
                  :type="
                    scope.row.s === '未使用'
                      ? 'success'
                      : scope.row.s === '已使用'
                      ? 'info'
                      : 'danger'
                  "
                  disable-transitions>{{scope.row.s}}</el-tag>
            </template>
          </el-table-column>


          <el-table-column prop="ct" width="160" label="创建时间" align="center">
          </el-table-column>

          <el-table-column prop="rd" width="150" label="核销设备" align="center">
          </el-table-column>

          <el-table-column prop="rt" width="160" label="核销时间" align="center">
          </el-table-column>

          <el-table-column prop="c" label="备注信息" align="center">
          </el-table-column>

          <el-table-column width="470">
            <template slot="header">
              <el-button
                  type="primary"
                  icon="el-icon-refresh"
                  style="margin-left: 30px;width: 140px"
                  @click="onRefresh"
              >刷新列表
              </el-button>
              <el-button
                  type="primary" plain
                  icon="el-icon-printer"
                  style="margin-left: 30px;width: 140px"
                  @click="ExportCouponExcel"
              >导出本页滤芯
              </el-button>

            </template>
            <template slot-scope="scope">

              <el-button style="margin-left: 10px" type="success" size="mini" icon="el-icon-full-screen" plain @click="qrCodeGeneration(scope.$index)">二维码</el-button>

              <el-divider direction="vertical"></el-divider>

              <el-popconfirm
                  :title="'滤芯码核销后不可撤销，确认将“'+scope.row.ci+'”（'+scope.row.cd+'）核销吗？'"
                  @confirm="FilterVerifyCommit(scope.$index)"
              >


                <el-button :disabled="scope.row.s == '已使用'"  size="mini" icon="el-icon-wallet"
                           type="primary"  plain slot="reference">离线款核销滤芯</el-button>
              </el-popconfirm>
<!--              <el-button size="mini" type="primary" icon="el-icon-edit" plain @click="OpenModifyDialog(scope.$index)">修改信息</el-button>-->
<!--              <el-button size="mini" plain-->
<!--                         @click="ChangePasswordDialog = true;InputPassword1='';InputPassword2='';ChangePasswordSN=tbdata[scope.$index].phone">-->
<!--                修改密码-->
<!--              </el-button>-->

<!--              <span v-if="pg_list.ui_coupon">-->
<!--                                <el-divider direction="vertical"></el-divider>-->
<!--                <el-button size="mini" type="primary" icon="el-icon-tickets"  @click="OpenModifyDialog(scope.$index)" plain>查看记录</el-button>-->
<!--              </span>-->

               <el-divider direction="vertical"></el-divider>
                  <el-popconfirm
                      :title="'滤芯码删除后不可恢复，确认将“'+scope.row.cd+'”（'+scope.row.ci+'）删除吗？'"
                      @confirm="DeleteCommit(scope.$index)"
                  >
                    <el-button  size="mini" icon="el-icon-delete"  type="danger"  plain slot="reference">删除滤芯</el-button>
                  </el-popconfirm>


            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currPage"
              :page-sizes="[10, 20, 30, 50,100]"
              :page-size="currSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="currTotal">
          </el-pagination>
        </div>
      </el-col>
      <!--      .........................-->


    </el-row>

    <!-- 修改密码 Start -->
<!--    <el-dialog title="修改客户密码" :visible.sync="ChangePasswordDialog" width="500px">-->
<!--                <el-form>-->
<!--                  <el-form-item label="新密码" label-width="80px">-->
<!--                    <el-input v-model="InputPassword1" show-password autocomplete="off" maxlength="32"></el-input>-->
<!--                  </el-form-item>-->
<!--                  <el-form-item label="确认密码" label-width="80px">-->
<!--                    <el-input v-model="InputPassword2" show-password autocomplete="off" maxlength="32"></el-input>-->
<!--                  </el-form-item>-->
<!--                </el-form>-->
<!--                <div slot="footer" class="dialog-footer">-->
<!--                  <el-button style="margin-right: 30px" @click="InputPassword1=666666;InputPassword2=666666">666666</el-button>-->

<!--                  <el-button @click="ChangePasswordDialog = false">取 消</el-button>-->
<!--                  <el-button type="primary" @click="ChangePassword">确 定</el-button>-->
<!--                </div>-->
<!--              </el-dialog>-->
              <!-- 修改密码 END -->

              <!-- 添加滤芯 Start -->
              <el-dialog title="添加滤芯" :visible.sync="CreateCodeDialog" width="500px">
                <el-form>
                  <el-form-item label="滤芯批次：" label-width="100px" >
                    <el-input clearable v-model="InputCodeDesc" autocomplete="off" maxlength="16"
                              show-word-limit>

                    </el-input>

                  </el-form-item>
                  <el-form-item label="滤芯编号：" label-width="100px" required>
                    <el-input clearable v-model="InputCodeId" autocomplete="off" maxlength="32"
                              show-word-limit></el-input>
                  </el-form-item>

                </el-form>
                <span style="padding-left: 9px">提示：随机生成的编号可能会重复，重复就修改一下或再生成一个。</span>
                <div slot="footer" class="dialog-footer">
                  <el-button type="success" plain style="margin-right: 70px" @click="GenCodeID">随机生成 6位编号</el-button>

                  <el-button @click="CreateCodeDialog = false">取 消</el-button>
                  <el-button type="primary" @click="CreateCodeCommit">添 加</el-button>
                </div>
              </el-dialog>
              <!-- 添加滤芯 END -->

    <!-- 批量添加滤芯 Start -->
    <el-dialog v-loading="MultiLoading" title="批量添加滤芯" :visible.sync="CreateMultiDialog" width="500px">
      <el-form>
        <el-form-item label="滤芯批次：" label-width="100px" >
          <el-input clearable v-model="InputMultiCodeDesc" autocomplete="off" maxlength="16"
                    show-word-limit>

          </el-input>

        </el-form-item>
        <el-form-item label="滤芯编号：" label-width="100px" required>
          <el-input-number v-model="InputMultiNum" :step="10" :min="1" :max="100"></el-input-number>

        </el-form-item>

      </el-form>
      <span style="padding-left: 9px">提示：推荐填写滤芯批次，方便筛选和导出本次生成的码。</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CreateMultiDialog = false">取 消</el-button>
        <el-button type="primary" @click="CreateMultiCodeCommit">添 加</el-button>
      </div>
    </el-dialog>
    <!-- 批量添加滤芯 END -->



    <el-dialog title="滤芯条码" :visible.sync="qrCodeVisible" width="440px" >
      <!-- 二维码图片 -->
      <el-image v-if="isqr" style="width: 400px; height: 400px" :src="qrcodeSrc" alt="QR Code"></el-image>
<!--      <div style="text-align: center;font-size: 28px;color: black;margin-top: -30px">{{qrcodeTxt}}</div>-->

      <el-image v-if="!isqr"  style="width: 400px; height: 150px;margin-top: 60px;" :src="barcodeSrc" alt="Bar Code"></el-image>
      <div style="text-align: center;font-size: 42px;color: black">{{qrcodeTxt}}</div>
      <div style="text-align: center;font-size: 36px;color: black">{{qrNameTxt}}</div>
      <!-- 取消按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-switch
            v-model="isqr"
            active-text="二维码"
            style="margin-right: 80px"
            inactive-text="条形码">
</el-switch>
                            <el-button @click="qrCodeVisible = false"  type="primary" >确定</el-button>
                        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info:{},
      searchinput: "",
      tbdata: [],


      // 状态
      idtselect: [{"value": "", "label": "不限"},
        {"value": "未使用", "label": "未使用"},
        {"value": "已使用", "label": "已使用"}],
      idtvalue: '',
      searchselect: [{"value": "id", "label": "按滤芯条码查找"},
        {"value": "name", "label": "按滤芯批次查找"},
        {"value": "sn", "label": "按核销设备查找"}
      ],
      searchvalue: 'id',
      shop:"",

      //
      currPage:1,
      currSize:10,
      currTotal:0,

      //QRCode
      isqr:true,
      qrCodeVisible: false,
      qrcodeSrc:"",
      barcodeSrc:"",
      qrcodeTxt:"",
      qrNameTxt:"",

      //修改密码
      // ChangePasswordSN: "",
      // ChangePasswordDialog: false,
      // InputPassword1: "",
      // InputPassword2: "",

      //创建条码
      CreateCodeDialog:false,
      InputCodeId:"",
      InputCodeDesc:"",
      //批量创建条码
      CreateMultiDialog:false,
      InputMultiCodeDesc:"",
      MultiLoading:"",
      InputMultiNum:10,

      // 用户资料
      modifyUserInfoDialog: false,
      modifyID: "",
      modifyName: "",
      modifyPhone: "",
      modifyComments: "",

      // 导出Excel
      cpHeader:
          {
            ci: '滤芯编号',
            cd: '滤芯批次',
            s: '滤芯状态',
            ct: '创建时间',
            rd: '核销设备',
            rt: '核销时间',
            c: '备注',
          },

      pg_list: {
        ui_coupon:false,
        //delete_customer: false,
        // charge_customer:false,
      },
    }
  },
  async created() {
    this.info = this.$store.state.userInfo


    await this.PG_Query();
    await this.onRefresh();
  },
  mounted() {

  },
  beforeDestroy() {
    //this.closeTimer()
  },
  methods: {



    async ModifyUserinfoCommit() {
      let res = await this.$api.update_customer_info({
        uid: this.modifyID,
        shop:this.shop,

        name: this.modifyName,
        phone: this.modifyPhone,
        comm: this.modifyComments
      })
      // console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.modifyUserInfoDialog = false
    },
    /// 新建用户  -----------------------------------------------------------------------------------------------------------

    // async DeleteUser(index) {
    //   this.$confirm('确认删除客户:"' + this.tbdata[index].name + '" ('+this.tbdata[index].phone+')？', '提示', {
    //     confirmButtonText: '确认删除',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(async () => {
    //     let res = await this.$api.delete_customer({
    //       phone: this.tbdata[index].phone,
    //       shop:this.shop
    //     })
    //     if (res.status == 1) {
    //       this.$message({
    //         message: '删除成功',
    //         type: 'success',
    //       })
    //
    //       this.onRefresh()
    //     }
    //   }).catch(() => {
    //     console.log("cancel")
    //   });
    //
    // },
    GenCodeNameYMD(){
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}${month}${day}-${hours}${minutes}${seconds}`
    },
    async CreateNewCode() {
      this.InputCodeId = ""
      this.InputCodeDesc = this.GenCodeNameYMD()
          this.CreateCodeDialog = true
    },
    async CreateMultiCode() {
      this.InputCodeId = ""
      this.InputMultiNum = 10
      this.InputMultiCodeDesc =this.GenCodeNameYMD()
      this.CreateMultiDialog = true
    },
    GenCodeID(){
      //const timestamp = Date.now();
      const randomNumber = Math.floor(Math.random() * (999999 - 111 + 1)) + 111;
      this.InputCodeId = (randomNumber).toString().padStart(6, '0')
    },
    async CreateCodeCommit() {
      let res = await this.$api.create_coupon({
        id:""+this.InputCodeId,
        desc:""+this.InputCodeDesc,
      })
      // console.log(res)
      if (res.status == 1) {
        this.$confirm('滤芯批次:"' + res.name + '"   滤芯条码:"' + res.id , '滤芯已创建', {
          confirmButtonText: '完成添加',
          cancelButtonText: '继续生成',
          type: 'success'
        }).then(() => {
          console.log("confirm")
          this.CreateCodeDialog = false
          this.onRefresh()
          // this.qrCodeGenerationByID(res.id,res.name)
        }).catch(() => {
          this.onRefresh()
          this.GenCodeID()
          console.log("cancel")

        });
      }
    },
    async CreateMultiCodeCommit() {
      this.MultiLoading = true
      let res = await this.$api.create_multi_coupon({
        num:""+this.InputMultiNum,
        desc:""+this.InputMultiCodeDesc,
      })
      // console.log(res)
      this.MultiLoading = false
      if (res.status == 1) {
        this.$confirm('已生成'+res.num+'个滤芯码，滤芯批次:"' + res.name  , '生成成功', {
          confirmButtonText: '完成生成',
          cancelButtonText: '继续生成',
          type: 'success'
        }).then(() => {
          console.log("confirm")
          this.CreateMultiDialog = false
          this.onRefresh()
        }).catch(() => {
          this.onRefresh()
          console.log("cancel")
        });
      }
    },

    // async AllocTimesCommit(index) {
    //   this.$prompt('请输入充值次数：', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //   }).then(async ({value}) => {
    //     // console.log("this.tbdata[index].phone,",this.tbdata[index].phone)
    //     let res = await this.$api.charge_customer({
    //       phone: this.tbdata[index].phone,
    //       shop:this.shop,
    //       times: ""+value,
    //     })
    //     console.log(res)
    //     if (res.status == 1) {
    //       this.$message({
    //         message: '充次成功',
    //         type: 'success',
    //       })
    //       this.onRefresh();
    //     }
    //   });
    //
    // },

    handleSizeChange(val) {
      this.currSize = val
      this.onRefresh()
    },
    handleCurrentChange(val) {
      this.currPage = val
      this.onRefresh()
    },

    async onRefresh() {
      let res = await this.$api.coupon_list({
        s: this.searchinput+"",
        t: this.idtvalue+"",
        p: this.currPage+"",
        z:this.currSize+"",
        by:this.searchvalue+""
      })
      // console.log(res)
      if (res.status == 1) {
        this.tbdata = res.data
        if(this.currTotal != res.total){
          this.currPage = 1
          this.currTotal = res.total // *2024年12月18日
          this.onRefresh()
        }
      }
    },
    async PG_Query() {
      let res = await this.$api.pg_query({
        pg: this.pg_list,
      })

      if (res.status == 1) {
        this.pg_list = res.data
      }

    },
    async DeleteCommit(index){
      let res = await this.$api.delete_coupon({
        ci: this.tbdata[index].ci
      })
      if (res.status == 1) {
        this.$message({
          message: '删除成功',
          type: 'success',
        })

        this.onRefresh()
      }
    },
    async FilterVerifyCommit(index) {
      let op = localStorage.getItem('op');
      if (op === null || op === '') {
        op = this.info.name;
        localStorage.setItem('op', op);
      }
      let res = await this.$api.filter_verify({
        ci:this.tbdata[index].ci+"",
        op:op,
        sn: "-",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '核销成功',
          type: 'success',
        })
        this.onRefresh();
      }
    },
    qrCodeGeneration(index){
      // console.log("QR",this.tbdata[index].sn)
      this.qrCodeVisible = true
      this.qrcodeTxt = this.tbdata[index].ci
      this.qrNameTxt= this.tbdata[index].cd
      this.qrcodeSrc = "https://api.ai-zhineng.com:24053/api/v7/qrcode?code="+this.qrcodeTxt
      this.barcodeSrc = "https://api.ai-zhineng.com:24053/api/v7/barcode?code="+this.qrcodeTxt
    },
    qrCodeGenerationByID(ID,TXT){
      this.qrCodeVisible = true
      this.qrcodeTxt = ID
      this.qrNameTxt= TXT
      this.qrcodeSrc = "https://api.ai-zhineng.com:24053/api/v7/qrcode?code="+this.qrcodeTxt
      this.barcodeSrc = "https://api.ai-zhineng.com:24053/api/v7/barcode?code="+this.qrcodeTxt
    },
    ExportCouponExcel(){
      console.log('exportExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let currentDate = new Date();
      let formattedDate = currentDate.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年') + '日';
      let name = "滤芯导出-"  + formattedDate + '.xlsx';
      let table = JSON.parse(JSON.stringify(this.tbdata))
      let map = []
      let cols = Object.keys(this.cpHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.cpHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.cpHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 120
      }
      widthMap['A'] = 100
      widthMap['C'] = 90
      widthMap['G'] = 180

      let colConf = this.$Excel.makeColConfig({...widthMap}, 70)
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: 'ebfade'}},
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
          }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: '67c23a'}},
              font: {color: {rgb: 'ffffff'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            return currentRow === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    },
  },
}
</script>

<style scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.detail-card .el-button {
  margin: 8px;
  margin-bottom: 14px;
  font-size: 16px;
  width: 92%
}

</style>
