<template>
  <div class="usercomp">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" class="demo-form-inline">

            <el-form-item label="售后状态筛选:" label-width="120px">
              <el-select v-model="idtvalue" @change="onRefresh" placeholder="请选择" style="width: 100px">
                <el-option
                    v-for="item in idtselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 20px" label="  选择条件查找:" label-width="120px">
              <el-select  v-model="searchvalue" placeholder="请选择" style="width: 160px">
                <el-option
                    v-for="item in searchselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  label="">
              <el-input  @clear="onRefresh()" clearable placeholder="输入查找内容" v-model="searchinput" @keyup.enter.native="onRefresh()">
                <el-button  slot="append" @click="onRefresh" icon="el-icon-search">查询</el-button>
              </el-input>
            </el-form-item>

            <el-button
                type="primary"
                icon="el-icon-refresh"
                style="margin-left: 30px;width: 140px"
                @click="onRefresh"
            >刷新列表
            </el-button>
            <el-button
                type="primary" plain
                icon="el-icon-printer"
                style="margin-left: 30px;width: 170px"
                @click="ExportServiceExcel"
            >导出本页售后记录
            </el-button>

          </el-form>

        </div>


        <el-table
            :data="tbdata"
            border
            stripe
            height="600"
            style="width: 100%; margin: 3px"
        >

          <el-table-column prop="title" width="230" label="售后标题" align="center">
          </el-table-column>

          <el-table-column prop="type" width="120" label="售后分类" align="center">
          </el-table-column>

          <el-table-column prop="cid" width="100" label="设备编号" align="center">
          </el-table-column>

          <el-table-column prop="status" width="90" label="售后状态" align="center">
            <template slot-scope="scope">
              <el-tag
                  :type="
                    scope.row.status === '已完成'
                      ? 'success'
                      : scope.row.status === '已关闭'
                      ? 'info'
                      : scope.row.status === '待处理'
                      ? 'warn'
                      : 'danger'
                  "
                  disable-transitions>{{scope.row.status}}</el-tag>
            </template>
          </el-table-column>


          <el-table-column prop="problem_desc"  label="情况描述" align="left">
          </el-table-column>

          <el-table-column prop="resolution_desc" label="处理方式" align="left">
          </el-table-column>

          <el-table-column  width="166" label="售后单创建人" align="center">
            <template slot-scope="scope">
              <div>{{scope.row.creator}}</div>
              <div>{{scope.row.create_time}}</div>
            </template>
          </el-table-column>

          <el-table-column  width="166" label="售后单处理人" align="center">
            <template slot-scope="scope">
              <div>{{scope.row.process}}</div>
              <div>{{scope.row.process_time}}</div>
            </template>
          </el-table-column>

          <el-table-column  width="166" label="售后单完成人" align="center">
            <template slot-scope="scope">
              <div>{{scope.row.completer}}</div>
              <div>{{scope.row.completed_time}}</div>
            </template>
          </el-table-column>

          <el-table-column  width="166" label="售后单关闭人" align="center">
            <template slot-scope="scope">
              <div>{{scope.row.closer}}</div>
              <div>{{scope.row.closed_time}}</div>
            </template>
          </el-table-column>


        </el-table>
        <div class="block">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currPage"
              :page-sizes="[10, 20, 30, 50,100]"
              :page-size="currSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="currTotal">
          </el-pagination>
        </div>
      </el-col>
      <!--      .........................-->


    </el-row>


  </div>
</template>

<script>
export default {
  data() {
    return {
      info:{},
      searchinput: "",
      tbdata: [],


      // 状态
      idtselect: [{"value": "", "label": "不限"},
        {"value": "待处理", "label": "待处理"},
        {"value": "处理中", "label": "处理中"},
        {"value": "已完成", "label": "已完成"},
        {"value": "已关闭", "label": "已关闭"}],
      idtvalue: '',
      searchselect: [
        {"value": "title", "label": "按售后标题查找"},
        {"value": "type", "label": "按售后分类查找"},
        {"value": "cid", "label": "按设备序号查找"},
        {"value": "creator", "label": "按创建者查找"},
        {"value": "process", "label": "按处理者查找"},
        {"value": "completer", "label": "按处理者查找"},
        {"value": "closer", "label": "按关闭者查找"},
        {"value": "sn", "label": "按4G编号查找"},
      ],
      searchvalue: 'title',
      shop:"",

      //
      currPage:1,
      currSize:10,
      currTotal:0,


      // 导出Excel
      cpHeader:
          {
            title: '售后标题',
            type: '售后分类',
            cid: '设备编号',
            status: '售后状态',
            problem_desc: '情况描述',
            resolution_desc: '处理方式',
            creator: '创建人',
            create_time: '创建时间',
            process: '处理人',
            process_time: '处理时间',
            completer: '完单人',
            completed_time: '完单时间',
            closer: '关单人',
            closed_time: '关单时间',
          },

      pg_list: {
        ui_coupon:false,
        //delete_customer: false,
        // charge_customer:false,
      },
    }
  },
  async created() {
    this.info = this.$store.state.userInfo


    await this.PG_Query();
    await this.onRefresh();
  },
  mounted() {

  },
  beforeDestroy() {
    //this.closeTimer()
  },
  methods: {

    handleSizeChange(val) {
      this.currSize = val
      this.onRefresh()
    },
    handleCurrentChange(val) {
      this.currPage = val
      this.onRefresh()
    },

    async onRefresh() {
      let res = await this.$api.service_all_list({
        s: this.searchinput+"", //查找内容
        t: this.idtvalue+"", // 售后状态
        p: this.currPage+"",
        z:this.currSize+"",
        by:this.searchvalue+"" // 查找选项
      })
      console.log(res)
      if (res.status == 1) {
        this.tbdata = res.data
        if(this.currTotal != res.total){
          this.currPage = 1
          this.currTotal = res.total // *2024年12月18日
          this.onRefresh()
        }

      }
    },
    async PG_Query() {
      let res = await this.$api.pg_query({
        pg: this.pg_list,
      })

      if (res.status == 1) {
        this.pg_list = res.data
      }

    },


    ExportServiceExcel(){
      console.log('exportExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let currentDate = new Date();
      let formattedDate = currentDate.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年') + '日';
      let name = "售后记录-"  + formattedDate + '导出.xlsx';
      let table = JSON.parse(JSON.stringify(this.tbdata))
      let map = []
      let cols = Object.keys(this.cpHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.cpHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.cpHeader)])
      // 单元格宽度循环设置

      widthMap['A'] = 170
      widthMap['B'] = 70
      widthMap['C'] = 80
      widthMap['D'] = 70
      widthMap['E'] = 300
      widthMap['F'] = 300

      widthMap['G'] = 90
      widthMap['H'] = 125
      widthMap['I'] = 90
      widthMap['J'] = 125
      widthMap['K'] = 90
      widthMap['L'] = 125
      widthMap['M'] = 90
      widthMap['N'] = 125


      let colConf = this.$Excel.makeColConfig({...widthMap}, 70)
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: 'ebfade'}},
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      this.$Excel.setExportCellStyle(
          map,
          `A2:${end}`,
          {
            s: {
              font: {color: {rgb: '000000'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
          }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
          map,
          `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
          {
            s: {
              fill: {bgColor: {indexed: 64}, fgColor: {rgb: '67c23a'}},
              font: {color: {rgb: 'ffffff'}},
              alignment: {horizontal: 'center', vertical: 'center'},
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            return currentRow === 0 ? newCell : cell // 隔行隔列上色
          }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    },
  },
}
</script>

<style scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.detail-card .el-button {
  margin: 8px;
  margin-bottom: 14px;
  font-size: 16px;
  width: 92%
}

</style>
